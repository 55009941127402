import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import ContentJobs from './../../Element/ContentJobs';
import api from './../Api';
import { Link } from 'react-router-dom';

const axios = require('axios');

const companiesInfo = {
    "Microsoft": {
        description: "Microsoft Corporation is an American multinational technology company that develops, manufactures, licenses, and sells computer software, consumer electronics, personal computers, and related services. Known for products like Windows, Office, Azure, and Xbox.",
        industry: "Technology",
        website: "https://www.microsoft.com",
        logo: "https://storage.googleapis.com/echojobs.io/static/logos/microsoft-com.png"
    },
    "SeatGeek": {
        description: "SeatGeek is a ticket search engine and marketplace that helps users find, compare, and purchase tickets for sports events, concerts, theater performances, and other live entertainment events.",
        industry: "Events",
        website: "https://www.seatgeek.com",
        logo: "https://storage.googleapis.com/echojobs.io/static/logos/seatgeek-com.png"
    },
    "Airbnb": {
        description: "Airbnb is an online marketplace that connects people who want to rent out their properties with people looking for accommodations, offering unique stays and experiences around the world.",
        industry: "Travel",
        website: "https://www.airbnb.com",
        logo: "https://storage.googleapis.com/echojobs.io/static/logos/airbnb-com.png"
    },
    "GitLab": {
        description: "GitLab is a complete DevOps platform delivered as a single application that enables organizations to implement and operate DevOps principles across their entire software development lifecycle.",
        industry: "Software Development",
        website: "https://www.gitlab.com",
        logo: "https://storage.googleapis.com/echojobs.io/static/logos/gitlab-com.png"
    },
    "SpaceX": {
        description: "SpaceX (Space Exploration Technologies Corp.) designs, manufactures, and launches advanced rockets and spacecraft with the ultimate goal of enabling people to live on other planets.",
        industry: "Aerospace",
        website: "https://www.spacex.com",
        logo: "https://storage.googleapis.com/echojobs.io/static/logos/spacex-com.png"
    },
    "Discord": {
        description: "Discord is a voice, video, and text communication platform that helps friends, communities, and developers talk and hang out regularly, making it easy to stay connected and collaborate.",
        industry: "Communication",
        website: "https://www.discord.com",
        logo: "https://www.opentoworkremote.com/company-logo/discord.jpeg"
    },
    "1Password": {
        description: "1Password is a password manager that securely stores passwords and other sensitive information, helping individuals and businesses protect their digital lives with strong encryption and user-friendly tools.",
        industry: "Security",
        website: "https://www.1password.com",
        logo: "https://storage.googleapis.com/echojobs.io/static/logos/1password-com.png"
    },
    "Automattic": {
        description: "Automattic is the company behind WordPress.com, Jetpack, WooCommerce, Tumblr, and other popular web services. They are pioneers in remote work with a distributed workforce across 93 countries.",
        industry: "Web Hosting",
        website: "https://automattic.com",
        logo: "https://storage.googleapis.com/echojobs.io/static/logos/automattic-com.png"
    },
    "aiApply": {
        description: "aiApply is an innovative AI-powered platform that streamlines the job application process by helping job seekers find and apply to remote positions that match their skills and preferences. The platform uses advanced algorithms to connect talent with opportunities worldwide.",
        industry: "HR Recruitment",
        website: "https://aiapply.com",
        logo: "https://www.opentoworkremote.com/company-logo/aiapply.jpg"
    },
    "Chess.com": {
        description: "Chess.com is a global online chess community with millions of members from around the world. It offers a wide range of features, including live games, tournaments, and a community forum.",
        industry: "Gaming",
        website: "https://chess.com",
        logo: "https://pbs.twimg.com/profile_images/1836047002712363008/Iaw1-QEl_400x400.png"
    },
    "Coinbase": {
        description: "Coinbase is a leading cryptocurrency exchange and platform that allows users to buy, sell, and store digital currencies securely. It offers a user-friendly interface for trading cryptocurrencies and provides a range of tools for managing digital assets.",
        industry: "Crypto",
        website: "https://coinbase.com",
        logo: "https://www.opentoworkremote.com/company-logo/Coinbase.png"
    },
    "Babylist": {
        description: "Babylist is a platform that helps parents find and share baby products, services, and information. It offers a range of tools for managing baby-related purchases and provides a community forum for parents to share advice and recommendations.",
        industry: "Parenting",
        website: "https://babylist.com",
        logo: "https://www.opentoworkremote.com/company-logo/Babylist.jpg"
    },
    "Kickstarter": {
        description: "Kickstarter is a global crowdfunding platform that allows anyone to fund creative projects. It offers a range of tools for managing projects and provides a community forum for creators to share advice and recommendations.",
        industry: "Crowdfunding",
        website: "https://kickstarter.com",
        logo: "https://www.opentoworkremote.com/company-logo/Kickstarter.png"
    },
    "SimplePractice": {
        description: "SimplePractice is a cloud-based practice management system designed for mental health professionals. It offers a range of tools for managing projects and provides a community forum for creators to share advice and recommendations.",
        industry: "Healthcare",
        website: "https://simplepractice.com",
        logo: "https://www.opentoworkremote.com/company-logo/Simplepractice55.ext.png"
    },
    "Hightouch": {
        description: "Hightouch is a cloud-based data integration platform that allows businesses to sync data between their systems and cloud applications. It offers a range of tools for managing projects and provides a community forum for creators to share advice and recommendations.",
        industry: "Healthcare",
        website: "https://hightouch.com",
        logo: "https://www.opentoworkremote.com/company-logo/Hightouch.png"
    },
    "Cribl": {
        description: "Cribl is a cloud-based data integration platform that allows businesses to sync data between their systems and cloud applications. It offers a range of tools for managing projects and provides a community forum for creators to share advice and recommendations.",
        industry: "Healthcare",
        website: "https://cribl.io",
        logo: "https://www.opentoworkremote.com/company-logo/Cribl.png"
    },
    "Invisible Technologies": {
        description: "Invisible Technologies is the AI training and scaling partner for the leading foundation model providers, enterprises, and governments, bridging the gap between AI potential and production.",
        industry: "AI",
        website: "https://www.invisible.co",
        logo: "https://www.opentoworkremote.com/company-logo/invisible.jpeg"
    },
    "Dropbox": {
        description: "Dropbox is a cloud storage and file sharing service that allows users to store, share, and collaborate on files and folders. It offers a range of tools for managing projects and provides a community forum for creators to share advice and recommendations.",
        industry: "Cloud Storage",
        website: "https://www.dropbox.com",
        logo: "https://upload.wikimedia.org/wikipedia/commons/7/78/Dropbox_Icon.svg"
    },
    "BigCommerce": {
        description: "BigCommerce is a leading cloud-based platform for building and scaling online stores.",
        industry: "E-commerce",
        website: "https://www.bigcommerce.com",
        logo: "https://pbs.twimg.com/profile_images/1808540604395036672/E72whSuq_400x400.jpg"
    },
    "Vercel": {
        description: "Vercel is a cloud platform for static sites and serverless functions.",
        industry: "E-commerce",
        website: "https://www.vercel.com",
        logo: "https://pbs.twimg.com/profile_images/1767351110228918272/3Pndc5OT_400x400.png"
    },
    "Amazon": {
        description: "Amazon is a multinational technology company focusing on e-commerce, cloud computing, digital streaming, and artificial intelligence. It is one of the world's most valuable companies and one of the largest online marketplaces and cloud service providers.",
        industry: "E-commerce",
        website: "https://www.amazon.com",
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/1200px-Amazon_logo.svg.png"
    }
};

class JobsByCompany extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true,
            companyInfo: null
        };
    }

    componentDidMount() {
        this.getJobs();
        this.getCompanyInfo();
    }

    getCompanyInfo() {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let companyParam = params.q || '';

        const companyKey = Object.keys(companiesInfo).find(
            key => key.toLowerCase() === companyParam.toLowerCase()
        );

        if (companyKey) {
            this.setState({
                companyInfo: companiesInfo[companyKey]
            });
        }
    }

    getJobs() {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let company = params.q || '';

        axios
            .request(api.getJobs('', '', '', company))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading, companyInfo } = this.state;

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let companyParam = params.q || null;

        if (!companyParam) {
            window.location.href = '/';
            return null;
        }

        const companyKey = Object.keys(companiesInfo).find(
            key => key.toLowerCase() === companyParam.toLowerCase()
        );
        const company = companyKey || companyParam;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>{`${company}: Find Jobs & Career Opportunities | OpenToWorkRemote`}</title>
                    <meta name="description" content={`Explore remote job opportunities at ${company}. Find and apply to the latest ${company} remote positions on OpenToWorkRemote.`} />
                    <link rel="canonical" href={'https://www.opentoworkremote.com/company/?q=' + company} />
                </Helmet>

                <div className="page-content bg-white">
                    <div className="dez-bnr-inr bg-dark" style={{padding: "15px 0"}}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry">
                                <Link to={""}>
                                    <h1 className="text-white" style={{
                                        fontSize: "calc(1.5rem + 1vw)", 
                                        fontWeight: "700", 
                                        margin: "0",
                                        lineHeight: "1.2"
                                    }}>
                                        Jobs and Careers at {company}
                                    </h1>
                                </Link>
                                <p className="text-white-50 mt-2">
                                    Explore the latest {company} job opportunities and start your career journey today
                                </p>
                            </div>
                        </div>
                    </div>

                    {companyInfo && (
                        <div className="container mt-4 mb-4">
                            <div className="company-profile-card bg-white p-4 rounded shadow-sm" style={{borderLeft: "4px solid #2e55fa"}}>
                                <div className="row align-items-center">
                                    {companyInfo.logo && (
                                        <div className="col-md-2 text-center mb-3 mb-md-0">
                                            <img src={companyInfo.logo} alt={`${company} logo`} className="img-fluid" 
                                                style={{
                                                    maxHeight: '90px',
                                                    maxWidth: '100%',
                                                    objectFit: 'contain'
                                                }} 
                                            />
                                        </div>
                                    )}
                                    <div className={`col-md-${companyInfo.logo ? '10' : '12'}`}>
                                        <h4 className="mb-2" style={{color: "#333", fontWeight: "600"}}>
                                            {company} Overview
                                            <span className="ms-4 badge" style={{
                                                fontSize: "0.7rem", 
                                                verticalAlign: "middle", 
                                                backgroundColor: "#28a745", 
                                                color: "white",
                                                padding: "4px 8px",
                                                borderRadius: "4px",
                                                marginLeft: "15px"
                                            }}>
                                                <i className="fa fa-check-circle me-1"></i> Verified
                                            </span>
                                        </h4>
                                        <h5 className="text-primary mb-3" style={{fontSize: "1.1rem", fontWeight: "500"}}>
                                            Find Jobs and Career Opportunities at {company}
                                        </h5>
                                        {companyInfo.description && (
                                            <p className="mb-3" style={{fontSize: "1rem", color: "#555"}}>
                                                {companyInfo.description}
                                            </p>
                                        )}
                                        <div className="company-details row mt-3" style={{fontSize: "0.95rem"}}>
                                            {companyInfo.industry && (
                                                <div className="detail-item col-md-4 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <i className="fa fa-building me-3" style={{color: "#2e55fa", fontSize: "1.2rem", minWidth: "20px"}}></i>
                                                        <div className="d-flex align-items-center">
                                                            <span style={{fontWeight: "600", color: "#444", minWidth: "70px"}}>Industry:</span>
                                                            <span className="ms-2">{companyInfo.industry}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {companyInfo.website && (
                                                <div className="detail-item col-md-4 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <i className="fa fa-globe me-3" style={{color: "#2e55fa", fontSize: "1.2rem", minWidth: "20px"}}></i>
                                                        <div className="d-flex align-items-center">
                                                            <span style={{fontWeight: "600", color: "#444", minWidth: "70px"}}>Website:</span>
                                                            <a href={`${companyInfo.website}?ref=opentoworkremote.com&utm_source=opentoworkremote.com`}
                                                               target="_blank"
                                                               rel="noopener noreferrer"
                                                               className="text-primary ms-2"
                                                               style={{textDecoration: "none", fontWeight: "500"}}>
                                                                {companyInfo.website.replace('https://', '')}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="mb-5" />

                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />
                </div>

                <Footer />
            </div>
        );
    }
}

export default JobsByCompany;
