import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from "./../Layout/Footer2";
import Header from "./../Layout/Header";
import { Helmet } from 'react-helmet';
import LogoComponent from './../Element/LogoComponent';

const axios = require("axios");

class JobDescription extends Component {

    constructor(props) {
        super(props);

        this.state = {
            myresponse: [],
            isLoading: true
        };

        const jobId = window.location.pathname.split('/')[2];

        this.getJob(jobId);
    }

    getJob(jobId) {
        var options = {};
        var headers = {};
        var url = "";

        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
            url = 'http://localhost:8080/jobs/' + jobId;
        } else {
            // url = "https://open-to-work-remote-api.p.rapidapi.com/jobs/" + jobId;
            // headers = {
            //     "X-RapidAPI-Key": "61c95e0e86msh784d6d5dfb4761dp1ed5e9jsn7923c8051dc2",
            //     "X-RapidAPI-Host": "open-to-work-remote-api.p.rapidapi.com",
            //     "X-Source": "OpenToWorkRemote.com",
            // };
            url = "https://opentoworkremote-api.herokuapp.com/jobs/" + jobId;
        }

        options = {
            method: "GET",
            url: url,
            params: {},
            headers: headers
        };

        axios
            .request(options)
            .then((response) => {
                this.setState({
                    myresponse: response.data,
                    isLoading: false
                });
            })
            .catch(function (error) {
                console.error(error);
                if (error.response && error.response.status === 404) {
                    console.log('Job Not Found, redirecting...');
                    window.location.href = '/';
                }
            });
    }

    decodeEntities(html) {
        const textarea = document.createElement('textarea');
        textarea.innerHTML = html;
        return textarea.value;
    }

    renderSkeletonLoader() {
        return (
            <div className="widget bg-white p-lr20 p-t20 widget_getintuch radius-sm">
                <br />
                <div className="text-center">
                    <div className="skeleton-circle"></div>
                </div>
                <div className="skeleton-line w-75 mx-auto mt-4"></div>
                <div className="skeleton-line w-50 mx-auto mb-4"></div>
                
                {/* <div className="skeleton-line w-25 mb-4">ss</div> */}
                
                <ul>
                    {[1, 2, 3, 4, 5].map((item, index) => (
                        <li key={index} className="d-flex align-items-center mb-3">
                            <div className="skeleton-circle-small mr-2"></div>
                            <div className="w-100">
                                <div className="skeleton-line w-25 mb-1"></div>
                                <div className="skeleton-line w-75"></div>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="text-center mt-4">
                    <div className="skeleton-button mx-auto"></div>
                </div>
                <br />
            </div>
        );
    }

    render() {
        const { myresponse, isLoading } = this.state;
        const jobId = window.location.pathname.split('/')[2];
        let canonical = "https://www.opentoworkremote.com/view/" + jobId;

        // const showGoBackButton = window.history.length > 1;

        const publicationDate = new Date(myresponse.publicationDate2);
        const currentDate = new Date();
        const differenceInTime = currentDate - publicationDate;
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        const isMoreThan30Days = differenceInDays > 30;

        const showTheJobDescription = myresponse.description && ['OpenToWorkRemote', 'Greenhouse', 'Lever', 'WWR', 'Workable', 'Remotive', 'WorkingNomads', 'Himalayas', 'LandingJobs', 'GetOnBrd', 'Zobjobs', 'SportsTech', 'SmartRecruiters', 'EchoJobs'].includes(myresponse.source);

        let urlToApply = `/apply/${myresponse.id}?url=${myresponse.url}`;
        if (myresponse && myresponse.url && myresponse.url.toString().indexOf("@") !== -1) {
            urlToApply = "mailto:" + myresponse.url;
        }

        const isDisabled = myresponse.enabled === 0;

        return (
            <div>
                <Helmet>
                    <title>
                        {isLoading 
                            ? 'Loading Job Details - Open To Work Remote'
                            : `${myresponse.title || ''} at ${myresponse.company || ''} | ${
                                myresponse.location === 'Remote.' || !myresponse.location
                                    ? 'Remote Job'
                                    : `${myresponse.location} Job`
                            }`
                        }
                    </title>
                    <meta name="description" content={
                        isLoading 
                            ? 'Loading remote job opportunity details...'
                            : `Apply now for ${myresponse.title} position at ${myresponse.company}. ${
                                myresponse.location === 'Remote.' || !myresponse.location
                                    ? 'Remote opportunity'
                                    : `${myresponse.location} opportunity`
                            }${
                                myresponse.salaryRange ? ` - Salary: ${myresponse.salaryRange}` : ''
                            }. Find the best remote jobs at Open To Work Remote.`
                    } />
                    <link rel="canonical" href={canonical} />

                    {/* Open Graph tags for social sharing */}
                    <meta property="og:title" content={`${myresponse.title || ''} at ${myresponse.company || ''}`} />
                    <meta property="og:description" content={`${myresponse.title || ''} position at ${myresponse.company || ''}. ${myresponse.location || 'Remote'} opportunity. Apply now!`} />
                    <meta property="og:url" content={canonical} />
                    <meta property="og:type" content="job" />
                    {myresponse.logo && <meta property="og:image" content={myresponse.logo} />}

                    {/* Twitter Card tags */}
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content={`${myresponse.title || ''} at ${myresponse.company || ''}`} />
                    <meta name="twitter:description" content={`${myresponse.title || ''} position at ${myresponse.company || ''}. ${myresponse.location || 'Remote'} opportunity. Apply now!`} />
                    {myresponse.logo && <meta name="twitter:image" content={myresponse.logo} />}

                    {/* Additional SEO meta tags */}
                    <meta name="keywords" content={`remote jobs, ${myresponse.title}, ${myresponse.company}, remote work, ${myresponse.location || 'remote'}, job opportunity`} />
                    <meta name="robots" content={isDisabled ? "noindex, nofollow" : "index, follow"} />
                    {isDisabled && <meta name="robots" content="noindex, nofollow" />}

                    {/* Job posting structured data */}
                    <script type="application/ld+json">
                        {`
                            {
                                "@context": "https://schema.org",
                                "@type": "JobPosting",
                                "title": "${myresponse.title || ''}",
                                "description": ${JSON.stringify(
                                    myresponse.description2
                                        ? myresponse.description2
                                        : `${myresponse.company} is hiring a ${myresponse.title} ${
                                            myresponse.location === 'Remote.' 
                                                ? 'for remote work' 
                                                : myresponse.location 
                                                    ? `in ${myresponse.location}` 
                                                    : 'for remote work'
                                        }${myresponse.workType ? `. This is a ${myresponse.workType} position` : ''}${
                                            myresponse.salaryRange ? ` with a salary range of ${myresponse.salaryRange}` : ''
                                        }. Join our team and work with a great company!`
                                )},
                                "datePosted": "${myresponse.publicationDate || ''}"
                                ${(!isDisabled && myresponse.publicationDate) ? `,
                                "validThrough": "${(() => {
                                    const date = new Date(new Date(myresponse.publicationDate).getTime() + (45 * 24 * 60 * 60 * 1000));
                                    date.setUTCHours(23, 59, 59, 999);
                                    return date.toISOString();
                                })()}"` : ''}
                                ${myresponse.workType ? `,"employmentType": "${myresponse.workType}"` : ''},
                                "hiringOrganization": {
                                    "@type": "Organization",
                                    "name": "${myresponse.company || ''}",
                                    "logo": "${myresponse.logo || ''}"
                                },
                                ${myresponse.location === 'Remote.' || !myresponse.location
                                    ? `"jobLocationType": "TELECOMMUTE",
                                       "applicantLocationRequirements": {
                                           "@type": "Country",
                                           "name": "Worldwide"
                                       }`
                                    : `"jobLocation": {
                                        "@type": "Place",
                                        "address": {
                                            "@type": "PostalAddress",
                                            "addressCountry": "${myresponse.location}"
                                        }
                                      }`
                                }
                                ${myresponse.salaryRange ? `
                                ,"baseSalary": {
                                    "@type": "MonetaryAmount",
                                    "currency": "USD",
                                    "value": {
                                        "@type": "QuantitativeValue",
                                        "value": "${myresponse.salaryRange}",
                                        "unitText": "YEAR"
                                    }
                                }` : ''}
                            }
                        `}
                    </script>
                </Helmet>

                <div className="page-wraper">
                    <Header />

                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark" style={{height: "100px"}}>
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <h1 className="text-white" style={{margin: "25px"}}>
                                        {!isLoading && `${myresponse.title} at ${myresponse.company}`}
                                        {isLoading && 'Loading Job Opportunity Details...'}
                                    </h1>
                                </div>
                            </div>
                        </div>

                        {isDisabled &&
                            <div className="content-block">
                                <div className="section-full content-inner-1">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-4"></div>
                                            <div className="col-lg-5">
                                                <div className="sticky-top">
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-6">
                                                            <div className="widget bg-white p-lr20 p-t20 widget_getintuch radius-sm">
                                                                <h4 className="text-red text-center">ATTENTION:</h4>
                                                                <h6 className="text-black text-center">This job posting is no longer active or has been disabled.</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="content-block">
                            <div className="section-full content-inner-1">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-4"></div>
                                        <div className="col-lg-5">
                                            {isLoading ? (
                                                this.renderSkeletonLoader()
                                            ) : (
                                                <div className="sticky-top">
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-6">
                                                            <div className="widget bg-white p-lr20 p-t20  widget_getintuch radius-sm">
                                                                <br />
                                                                <div className="text-center">
                                                                    <LogoComponent logoUrl={myresponse.logo} />
                                                                </div>
                                                                <h2 className="text-black font-weight-700 p-t15 m-b15 text-center">{myresponse.company} is Hiring</h2>
                                                                <h5 className="text-black font-weight-700 p-t10 m-b15 text-left">Job Info:</h5>
                                                                <ul>
                                                                    <li><i className="ti-target"></i><strong className="font-weight-700 text-black">Company</strong><span className="text-black-light"><Link to={`/company?q=${myresponse.company}`} > {myresponse.company} </Link></span></li>
                                                                    <li><i className="ti-marker-alt"></i><strong className="font-weight-700 text-black">Position</strong> {myresponse.title} </li>
                                                                    {myresponse.location && (
                                                                        <li><i className="ti-location-pin"></i><strong className="font-weight-700 text-black">Location</strong> {myresponse.location} </li>
                                                                    )}
                                                                    <li><i className="ti-search"></i><strong className="font-weight-700 text-black">Source</strong> {myresponse.source} </li>
                                                                    <li><i className="ti-calendar"></i><strong className="font-weight-700 text-black">Published</strong> {myresponse.publicationDate2} 
                                                                        {isMoreThan30Days && 
                                                                            <span className="text-red" style={{
                                                                                fontSize: '0.8em',
                                                                                marginLeft: '5px',
                                                                                fontStyle: 'italic'
                                                                            }}>
                                                                                (30+ days ago)
                                                                            </span>
                                                                        }
                                                                    </li>
                                                                    {myresponse.category && (
                                                                        <li><i className="ti-tag"></i><strong className="font-weight-700 text-black">Category</strong> {myresponse.category}</li>
                                                                    )}
                                                                    {myresponse.workType && (
                                                                        <li><i className="ti-time"></i><strong className="font-weight-700 text-black">Type</strong> {myresponse.workType} </li>
                                                                    )}
                                                                    {myresponse.experience && (
                                                                        <li><i className="ti-user"></i><strong className="font-weight-700 text-black">Experience</strong> {myresponse.experience} </li>
                                                                    )}
                                                                    {myresponse.salaryRange && (
                                                                        <li><i className="ti-money"></i><strong className="font-weight-700 text-black">Salary</strong> {myresponse.salaryRange} </li>
                                                                    )}
                                                                </ul>
                                                                <br />
                                                                {!showTheJobDescription && !isDisabled && (
                                                                    <div className="text-center">
                                                                        <a href={urlToApply}
                                                                            className="site-button apply-button btn-xl m-2"
                                                                            target="_blank"
                                                                            rel="nofollow noopener noreferrer"
                                                                        >
                                                                            <span className="fa fa-paper-plane"> </span>
                                                                            Go To Job Description
                                                                        </a>
                                                                        <br /><br />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-2"></div>
                                        <div className="col-lg-8">
                                            <div className="sticky-top">
                                                <div>
                                                    {showTheJobDescription && !isDisabled && (
                                                        <div>
                                                            <br />
                                                            <div className="job-description-section">
                                                                <h4 className="text-black font-weight-700 text-center mb-4">
                                                                    Job Description
                                                                </h4>
                                                                <div className="job-description-content p-4 bg-white rounded" 
                                                                    dangerouslySetInnerHTML={{ 
                                                                        __html: this.decodeEntities(myresponse.description) 
                                                                    }} 
                                                                />
                                                            </div>
                                                            <br />
                                                            <div className="text-center mt-4 pt-3 border-top">
                                                                <a href={urlToApply}
                                                                    className="site-button apply-button btn-lg m-2"
                                                                    target="_blank"
                                                                    rel="nofollow noopener noreferrer"
                                                                >
                                                                    <i className="fa fa-paper-plane mr-2"></i>
                                                                    Go To Apply
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <br /><br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default JobDescription;
