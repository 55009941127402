import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsBackend extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('Backend', ''))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Find Jobs in Backend: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in Backend? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-backend" />
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "What skills are required for backend development?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Backend developers typically need proficiency in server-side languages (like Python, Java, Node.js, Ruby), database management (SQL/NoSQL), API development, server architecture, and security principles. Knowledge of cloud services (AWS, Azure, GCP) is increasingly important."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What's the difference between frontend and backend development?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Frontend development focuses on what users see and interact with directly (UI/UX), using HTML, CSS, and JavaScript. Backend development handles server-side logic, databases, and application architecture - the 'behind the scenes' functionality that powers web applications."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the most in-demand backend technologies?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Currently, Node.js, Python (Django, Flask), Java (Spring), Ruby on Rails, PHP (Laravel), and .NET are highly sought after. For databases, PostgreSQL, MongoDB, MySQL, and Redis are popular. Containerization (Docker) and orchestration (Kubernetes) skills are also increasingly valuable."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can I prepare for a backend developer interview?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Study data structures and algorithms, system design principles, and your chosen backend technologies. Practice coding challenges on platforms like LeetCode or HackerRank. Be prepared to discuss past projects, explain architectural decisions, and demonstrate problem-solving skills related to scalability, performance, and security."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in Backend
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Dreaming of the ideal backend job? Let's make it a reality!</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myTitle="Backend" />

                    </div>

                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Backend Jobs</h2>
                                    <p>Common questions about backend development careers</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What skills are required for backend development?</h4>
                                        <p>Backend developers typically need proficiency in server-side languages (like Python, Java, Node.js, Ruby), database management (SQL/NoSQL), API development, server architecture, and security principles. Knowledge of cloud services (AWS, Azure, GCP) is increasingly important.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What's the difference between frontend and backend development?</h4>
                                        <p>Frontend development focuses on what users see and interact with directly (UI/UX), using HTML, CSS, and JavaScript. Backend development handles server-side logic, databases, and application architecture - the "behind the scenes" functionality that powers web applications.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the most in-demand backend technologies?</h4>
                                        <p>Currently, Node.js, Python (Django, Flask), Java (Spring), Ruby on Rails, PHP (Laravel), and .NET are highly sought after. For databases, PostgreSQL, MongoDB, MySQL, and Redis are popular. Containerization (Docker) and orchestration (Kubernetes) skills are also increasingly valuable.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How can I prepare for a backend developer interview?</h4>
                                        <p>Study data structures and algorithms, system design principles, and your chosen backend technologies. Practice coding challenges on platforms like LeetCode or HackerRank. Be prepared to discuss past projects, explain architectural decisions, and demonstrate problem-solving skills related to scalability, performance, and security.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Footer />
            </div>
        );
    }
}

export default JobsBackend;
