import React, { Component } from "react";
import Footer from "../../Layout/Footer2";
import Header from "../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from '../Api';
import { Link } from "react-router-dom";
import ContentJobs from '../../Element/ContentJobs';
import Jobfindbox from "../../Element/Jobfindbox2";

const axios = require('axios');

class JobsInIreland extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('', 'Ireland'))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Jobs in Ireland: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in Ireland? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-in-ireland" />
                </Helmet>

                <div className="page-content bg-white">
                    <div className="dez-bnr-inr bg-dark">
                        <div className="container">
                            <div className="dez-bnr-inr-entry">
                                <Link to={""}>
                                    <h1 className="text-white">
                                        Find jobs in Ireland
                                    </h1>
                                </Link>
                                <div className="breadcrumb-row">
                                    <ul className="">
                                        <li>Connect with Irish companies offering remote work opportunities</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Jobfindbox myLocation="Ireland" />

                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Remote Jobs in Ireland</h2>
                                    <p>Common questions about remote work opportunities in the Emerald Isle</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">Why is Ireland a leading destination for remote work?</h4>
                                        <p>Ireland has established itself as a major European tech hub, hosting EMEA headquarters for many global technology companies. The country's National Remote Work Strategy promotes flexible working arrangements across all sectors. Dublin's Silicon Docks and regional tech clusters offer diverse opportunities for remote workers. Ireland's position as an English-speaking EU country makes it attractive for international companies and workers. The government's investment in digital infrastructure and recent remote working legislation supports flexible work arrangements. Additionally, Ireland's competitive corporate tax rates and skilled workforce attract major employers offering remote positions.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the most in-demand remote skills in Ireland?</h4>
                                        <p>Software development skills are highly sought after, particularly in cloud computing, AI, and data analytics. Ireland's strong pharmaceutical and medical device sector creates demand for professionals in health tech and regulatory technology. Cybersecurity experts are increasingly needed as Ireland becomes a hub for data centers and fintech operations. Digital marketing specialists who understand EU markets and GDPR compliance are valuable. There's significant demand for technical support and customer success roles, especially multilingual positions serving EMEA markets. Project managers with experience in agile methodologies and international team management are consistently in demand.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How do employment and benefits work for remote workers in Ireland?</h4>
                                        <p>Remote workers in Ireland benefit from strong employment protections under Irish law. Standard benefits include 20 days minimum annual leave, public holidays, and parental leave entitlements. The Right to Request Remote Working Bill establishes framework for remote work arrangements. Employers must ensure proper home office setup and compliance with health and safety regulations. PRSI (social insurance) contributions provide access to state benefits. Remote workers can claim tax relief on home working expenses through Revenue's e-worker relief. For contractors, understanding the differences between PAYE employment and self-employment is crucial. Many companies offer additional benefits like private health insurance and pension contributions.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How can professionals succeed in Ireland's remote work environment?</h4>
                                        <p>Success in Ireland's remote work landscape requires staying connected with the country's vibrant tech community through organizations like TechIreland and local meetup groups. Understanding both Irish and EU business practices is important for career growth. Many remote workers take advantage of government-supported upskilling programs through Skillnet Ireland and local tech academies. Networking through Ireland's strong tech ecosystem, including events in Dublin's Silicon Docks and regional hubs, is valuable. Familiarity with GDPR and data protection requirements is essential. Many successful remote workers participate in Ireland's growing digital hub network and coworking spaces for occasional in-person collaboration and networking.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

                <Helmet>
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "Why is Ireland a leading destination for remote work?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Ireland has established itself as a major European tech hub, hosting EMEA headquarters for many global technology companies. The country's National Remote Work Strategy promotes flexible working arrangements across all sectors. Dublin's Silicon Docks and regional tech clusters offer diverse opportunities for remote workers. Ireland's position as an English-speaking EU country makes it attractive for international companies and workers. The government's investment in digital infrastructure and recent remote working legislation supports flexible work arrangements. Additionally, Ireland's competitive corporate tax rates and skilled workforce attract major employers offering remote positions."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the most in-demand remote skills in Ireland?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Software development skills are highly sought after, particularly in cloud computing, AI, and data analytics. Ireland's strong pharmaceutical and medical device sector creates demand for professionals in health tech and regulatory technology. Cybersecurity experts are increasingly needed as Ireland becomes a hub for data centers and fintech operations. Digital marketing specialists who understand EU markets and GDPR compliance are valuable. There's significant demand for technical support and customer success roles, especially multilingual positions serving EMEA markets. Project managers with experience in agile methodologies and international team management are consistently in demand."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How do employment and benefits work for remote workers in Ireland?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote workers in Ireland benefit from strong employment protections under Irish law. Standard benefits include 20 days minimum annual leave, public holidays, and parental leave entitlements. The Right to Request Remote Working Bill establishes framework for remote work arrangements. Employers must ensure proper home office setup and compliance with health and safety regulations. PRSI (social insurance) contributions provide access to state benefits. Remote workers can claim tax relief on home working expenses through Revenue's e-worker relief. For contractors, understanding the differences between PAYE employment and self-employment is crucial. Many companies offer additional benefits like private health insurance and pension contributions."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can professionals succeed in Ireland's remote work environment?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Success in Ireland's remote work landscape requires staying connected with the country's vibrant tech community through organizations like TechIreland and local meetup groups. Understanding both Irish and EU business practices is important for career growth. Many remote workers take advantage of government-supported upskilling programs through Skillnet Ireland and local tech academies. Networking through Ireland's strong tech ecosystem, including events in Dublin's Silicon Docks and regional hubs, is valuable. Familiarity with GDPR and data protection requirements is essential. Many successful remote workers participate in Ireland's growing digital hub network and coworking spaces for occasional in-person collaboration and networking."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>
            </div>
        );
    }
}

export default JobsInIreland;
