import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer2';
import { Helmet } from 'react-helmet';

const randomNumber = Math.floor(Math.random() * 5) + 10;
const bnr = require('./../../images/banner/bnr' + randomNumber + '.jpg');

class Categoryjobs extends Component {
    render() {
        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Explore remote jobs by categories</title>
                    <meta name="description" content="Explore a diverse range of remote job opportunities categorized by industry. Your source for remote work. #OpenToWorkRemote" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-by-category" />
                </Helmet>

                <div className="page-content">
                    <div className="dez-bnr-inr jobs-category overlay-black-middle" style={{backgroundImage: "url(" + bnr + ")"}}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry">
                                <h1 className="text-white">
                                    Explore remote jobs by categories
                                </h1>
                            </div>
                        </div>
                    </div>	
                    <div className="content-block">
                        <div className="section-full content-inner jobs-category-bx">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Browse Remote Jobs by Categories<Link to = {"/all-jobs-by-category"} style={{display: "block"}} className="float-right font-12 text-primary">View All</Link></h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-6">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/remote-jobs-php"}>Remote PHP Jobs</Link></li>
                                                        <li><Link to = {"/remote-jobs-backend"}>Remote Backend Jobs</Link></li>
                                                        <li><Link to = {"/remote-jobs-frontend"}>Remote Frontend Jobs</Link></li>
                                                        <li><Link to = {"/remote-jobs-fullstack"}>Remote FullStack Jobs</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-4 col-sm-6">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/remote-jobs-marketing"}>Remote Marketing Jobs</Link></li>
                                                        <li><Link to = {"/remote-jobs-sales"}>Remote Sales Jobs</Link></li>
                                                        <li><Link to = {"/remote-jobs-human-resource"}>Remote HR Jobs</Link></li>
                                                        <li><Link to = {"/remote-jobs-qa"}>Remote QA Jobs</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-4 col-sm-6">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/remote-jobs-site-reliability-engineer"}>Remote SRE Jobs</Link></li>
                                                        <li><Link to = {"/remote-jobs-python"}>Remote Python Jobs</Link></li>
                                                        <li><Link to = {"/remote-jobs-accountant"}>Remote Accountant Jobs</Link></li>
                                                        <li><Link to = {"/remote-jobs-data-engineer"}>Remote Data Engineer Jobs</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Most Searched Jobs<Link to = {"/all-jobs-by-category"} style={{display: "block"}} className="float-right font-12 text-primary">View All</Link></h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-6">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs-laravel"}>Laravel Jobs</Link></li>
                                                        <li><Link to = {"/jobs-golang"}>Golang Jobs</Link></li>
                                                        <li><Link to = {"/jobs-ruby"}>Ruby Jobs</Link></li>
                                                        <li><Link to = {"/jobs-react"}>React Jobs</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-4 col-sm-6">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs?title=Product Manager&location="}>Product Manager Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Project Manager&location="}>Project Manager Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Program Manager&location="}>Program Manager Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Operations Manager&location="}>Operations Manager Jobs</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-4 col-sm-6">
                                                    <ul className="category-list">
                                                        <li><Link to = {"/jobs-account-executive"}>Account Executive Jobs</Link></li>
                                                        <li><Link to = {"/jobs-customer-success"}>Customer Success Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Business Operations&location="}>Business Operations Jobs</Link></li>
                                                        <li><Link to = {"/jobs?title=Engineering Manager&location="}>Engineering Manager Jobs</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};

export default Categoryjobs;
