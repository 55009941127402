import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsInMexico extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('', 'Mexico'))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Jobs in Mexico: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in Mexico? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-in-mexico" />
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in Mexico
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Find the best remote opportunities in Mexico. From innovative startups to leading Mexican corporations.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myLocation="Mexico" />

                    </div>
                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Remote Jobs in Mexico</h2>
                                    <p>Common questions about remote work opportunities in Mexico's growing digital economy</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">Why is Mexico becoming a leading destination for remote work?</h4>
                                        <p>Mexico has emerged as a prime remote work hub due to its strategic location and time zone alignment with North American companies. Major tech ecosystems in cities like Mexico City, Guadalajara (known as Mexico's Silicon Valley), and Monterrey offer diverse opportunities. The country's strong technical education system produces highly skilled professionals in technology and digital services. Recent reforms in telecommuting laws (NOM-037) provide clear frameworks for remote work arrangements. Mexico's competitive cost structure combined with high-quality talent makes it attractive for international companies. Additionally, the growing presence of global tech companies and startups creates a dynamic environment for remote workers.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the most in-demand remote skills in Mexico?</h4>
                                        <p>Software development skills are highly valued, particularly in web development, mobile apps, and cloud technologies. Mexico's growing fintech sector creates strong demand for professionals in financial technology and digital payments. There's significant need for data scientists and AI specialists as companies embrace digital transformation. UX/UI designers from Mexico are valued for their creativity and understanding of North American markets. Digital marketing specialists who can navigate both Mexican and international markets are in high demand. Bilingual professionals (Spanish/English) are particularly sought after for roles involving international collaboration. Project managers with experience in agile methodologies and cross-cultural team management are consistently needed.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How do employment and benefits work for remote workers in Mexico?</h4>
                                        <p>Remote workers in Mexico typically operate either through formal employment contracts or as independent contractors ('personas físicas con actividad empresarial'). The Mexican Federal Labor Law provides strong protections, including mandatory benefits like aguinaldo, vacation days, and profit sharing (PTU). Remote work arrangements must comply with NOM-037 requirements regarding equipment, expenses, and work conditions. Social security (IMSS) and housing fund (INFONAVIT) contributions are mandatory for employees. Independent contractors must register with SAT and manage their own tax obligations. Many companies use international payment platforms or local entities to handle payroll. Understanding both Mexican labor laws and international payment regulations is crucial for remote workers.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How can professionals succeed in Mexico's remote work environment?</h4>
                                        <p>Success in Mexico's remote work landscape requires staying connected with the country's tech community through organizations like AMITI and local tech meetups. Many professionals benefit from participating in Mexico's growing startup ecosystem and tech events. Maintaining strong English skills is crucial for international opportunities. Understanding both Mexican business practices and global work cultures is important. Many successful remote workers join coworking spaces and tech hubs across major Mexican cities for networking and professional development. Taking advantage of online learning platforms and bootcamps focused on the Mexican market can enhance career prospects. Participating in communities like Startup Mexico and Mexican tech associations helps build valuable professional networks.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

                <Helmet>
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "Why is Mexico becoming a leading destination for remote work?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Mexico has emerged as a prime remote work hub due to its strategic location and time zone alignment with North American companies. Major tech ecosystems in cities like Mexico City, Guadalajara (known as Mexico's Silicon Valley), and Monterrey offer diverse opportunities. The country's strong technical education system produces highly skilled professionals in technology and digital services. Recent reforms in telecommuting laws (NOM-037) provide clear frameworks for remote work arrangements. Mexico's competitive cost structure combined with high-quality talent makes it attractive for international companies. Additionally, the growing presence of global tech companies and startups creates a dynamic environment for remote workers."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the most in-demand remote skills in Mexico?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Software development skills are highly valued, particularly in web development, mobile apps, and cloud technologies. Mexico's growing fintech sector creates strong demand for professionals in financial technology and digital payments. There's significant need for data scientists and AI specialists as companies embrace digital transformation. UX/UI designers from Mexico are valued for their creativity and understanding of North American markets. Digital marketing specialists who can navigate both Mexican and international markets are in high demand. Bilingual professionals (Spanish/English) are particularly sought after for roles involving international collaboration. Project managers with experience in agile methodologies and cross-cultural team management are consistently needed."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How do employment and benefits work for remote workers in Mexico?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote workers in Mexico typically operate either through formal employment contracts or as independent contractors ('personas físicas con actividad empresarial'). The Mexican Federal Labor Law provides strong protections, including mandatory benefits like aguinaldo, vacation days, and profit sharing (PTU). Remote work arrangements must comply with NOM-037 requirements regarding equipment, expenses, and work conditions. Social security (IMSS) and housing fund (INFONAVIT) contributions are mandatory for employees. Independent contractors must register with SAT and manage their own tax obligations. Many companies use international payment platforms or local entities to handle payroll. Understanding both Mexican labor laws and international payment regulations is crucial for remote workers."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can professionals succeed in Mexico's remote work environment?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Success in Mexico's remote work landscape requires staying connected with the country's tech community through organizations like AMITI and local tech meetups. Many professionals benefit from participating in Mexico's growing startup ecosystem and tech events. Maintaining strong English skills is crucial for international opportunities. Understanding both Mexican business practices and global work cultures is important. Many successful remote workers join coworking spaces and tech hubs across major Mexican cities for networking and professional development. Taking advantage of online learning platforms and bootcamps focused on the Mexican market can enhance career prospects. Participating in communities like Startup Mexico and Mexican tech associations helps build valuable professional networks."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>
            </div>
        );
    }
}

export default JobsInMexico;
