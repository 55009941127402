import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsSales extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('Sales ', ''))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Find Jobs in Sales: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in Sales? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-sales" />
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "What skills are needed for remote sales jobs?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Successful remote sales professionals need strong communication skills, self-discipline, time management abilities, and technical proficiency with CRM systems and digital sales tools. They must excel at building relationships virtually, giving compelling remote presentations, and staying motivated without in-person supervision."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How is remote sales different from in-office sales?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote sales relies more heavily on digital communication tools and requires stronger self-management skills. While in-office sales may benefit from spontaneous collaboration and in-person client meetings, remote sales offers flexibility, broader market reach, and often results in higher productivity with fewer distractions."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What industries offer the most remote sales opportunities?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "SaaS (Software as a Service), technology, digital marketing, e-commerce, financial services, and healthcare industries typically offer the most remote sales opportunities. These sectors have embraced digital transformation and have products or services that can be effectively sold through virtual channels."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can I prepare for a remote sales interview?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Prepare by researching the company and its products thoroughly, practicing your virtual presentation skills, demonstrating your experience with remote sales tools, and preparing stories about how you've succeeded in self-directed work environments. Be ready to discuss your home office setup and how you maintain productivity when working remotely."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in Sales
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Find the best remote Sales opportunities worldwide. From Sales Representatives to VPs of Sales.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myTitle="Sales " />

                    </div>

                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Sales Jobs</h2>
                                    <p>Common questions about remote sales careers</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What skills are needed for remote sales jobs?</h4>
                                        <p>Successful remote sales professionals need strong communication skills, self-discipline, time management abilities, and technical proficiency with CRM systems and digital sales tools. They must excel at building relationships virtually, giving compelling remote presentations, and staying motivated without in-person supervision.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How is remote sales different from in-office sales?</h4>
                                        <p>Remote sales relies more heavily on digital communication tools and requires stronger self-management skills. While in-office sales may benefit from spontaneous collaboration and in-person client meetings, remote sales offers flexibility, broader market reach, and often results in higher productivity with fewer distractions.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What industries offer the most remote sales opportunities?</h4>
                                        <p>SaaS (Software as a Service), technology, digital marketing, e-commerce, financial services, and healthcare industries typically offer the most remote sales opportunities. These sectors have embraced digital transformation and have products or services that can be effectively sold through virtual channels.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How can I prepare for a remote sales interview?</h4>
                                        <p>Prepare by researching the company and its products thoroughly, practicing your virtual presentation skills, demonstrating your experience with remote sales tools, and preparing stories about how you've succeeded in self-directed work environments. Be ready to discuss your home office setup and how you maintain productivity when working remotely.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Footer />
            </div>
        );
    }
}

export default JobsSales;
