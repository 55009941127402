import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsInRemote extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('', 'Remote.'))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Remote Jobs Worldwide: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs 100% remote? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/remote-jobs-worldwide" />
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "What are the benefits of working remotely?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote work offers numerous benefits including elimination of commuting time and costs, improved work-life balance, and the freedom to create a personalized work environment. Many remote workers report higher productivity due to fewer office distractions and the ability to work during their peak performance hours. Remote jobs often provide geographic flexibility, allowing professionals to live where they choose regardless of company location. This can mean access to a global job market, potentially higher compensation, and the ability to relocate without changing employers. Additionally, remote work can reduce stress, save money on professional wardrobe and meals, and accommodate personal needs like childcare or health conditions that might make traditional office work challenging."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can I stand out when applying for remote jobs?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "To stand out when applying for remote jobs, highlight previous remote work experience or self-directed projects that demonstrate your ability to work independently. Emphasize strong written communication skills, as this is crucial in remote environments. Showcase your proficiency with collaboration tools like Slack, Asana, Trello, and video conferencing platforms. Tailor your resume to include keywords from the job description, as remote positions often use applicant tracking systems. Create a compelling online presence through a professional portfolio, LinkedIn profile, or GitHub repository (for technical roles). Address potential employer concerns by emphasizing your self-discipline, time management abilities, and proactive communication style. Finally, research the company thoroughly and personalize your application to show genuine interest in their specific remote culture and values."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What equipment and setup do I need for successful remote work?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "A successful remote work setup requires reliable high-speed internet (ideally with a backup solution) and a computer that meets your job requirements. Invest in ergonomic furniture—particularly a quality chair and desk at proper heights to prevent physical strain during long work hours. A dedicated workspace, even if small, helps maintain work-life boundaries and professional video call backgrounds. Essential accessories include a good quality webcam, microphone, and headphones for clear communication. Consider a second monitor to enhance productivity for most roles. Proper lighting (preferably natural light supplemented with adjustable lamps) improves both your work experience and video call appearance. Additional helpful items include a UPS/surge protector, ergonomic keyboard and mouse, and noise-cancelling technology if working in a noisy environment."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can I maintain work-life balance in a remote position?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Maintaining work-life balance in remote positions starts with establishing clear boundaries—creating a dedicated workspace and setting defined working hours that you communicate to both colleagues and household members. Develop consistent daily routines with a proper morning ritual and end-of-day shutdown procedure to mentally transition between work and personal time. Take regular breaks using techniques like the Pomodoro method (25 minutes of work followed by 5-minute breaks) to prevent burnout. Schedule non-negotiable personal time for exercise, hobbies, and social connections. Use technology intentionally by turning off notifications after hours and using separate work/personal devices or profiles when possible. Finally, regularly assess your balance by checking in with yourself about stress levels and satisfaction, and be willing to adjust your boundaries and communicate needs to your employer when necessary."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find remote jobs worldwide
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Find the best remote opportunities worldwide. From entry-level positions to executive roles.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myLocation="Remote." />

                    </div>
                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />
                    
                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Remote Jobs</h2>
                                    <p>Common questions about finding and succeeding in remote work</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the benefits of working remotely?</h4>
                                        <p>Remote work offers numerous benefits including elimination of commuting time and costs, improved work-life balance, and the freedom to create a personalized work environment. Many remote workers report higher productivity due to fewer office distractions and the ability to work during their peak performance hours. Remote jobs often provide geographic flexibility, allowing professionals to live where they choose regardless of company location. This can mean access to a global job market, potentially higher compensation, and the ability to relocate without changing employers. Additionally, remote work can reduce stress, save money on professional wardrobe and meals, and accommodate personal needs like childcare or health conditions that might make traditional office work challenging.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How can I stand out when applying for remote jobs?</h4>
                                        <p>To stand out when applying for remote jobs, highlight previous remote work experience or self-directed projects that demonstrate your ability to work independently. Emphasize strong written communication skills, as this is crucial in remote environments. Showcase your proficiency with collaboration tools like Slack, Asana, Trello, and video conferencing platforms. Tailor your resume to include keywords from the job description, as remote positions often use applicant tracking systems. Create a compelling online presence through a professional portfolio, LinkedIn profile, or GitHub repository (for technical roles). Address potential employer concerns by emphasizing your self-discipline, time management abilities, and proactive communication style. Finally, research the company thoroughly and personalize your application to show genuine interest in their specific remote culture and values.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What equipment and setup do I need for successful remote work?</h4>
                                        <p>A successful remote work setup requires reliable high-speed internet (ideally with a backup solution) and a computer that meets your job requirements. Invest in ergonomic furniture—particularly a quality chair and desk at proper heights to prevent physical strain during long work hours. A dedicated workspace, even if small, helps maintain work-life boundaries and professional video call backgrounds. Essential accessories include a good quality webcam, microphone, and headphones for clear communication. Consider a second monitor to enhance productivity for most roles. Proper lighting (preferably natural light supplemented with adjustable lamps) improves both your work experience and video call appearance. Additional helpful items include a UPS/surge protector, ergonomic keyboard and mouse, and noise-cancelling technology if working in a noisy environment.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How can I maintain work-life balance in a remote position?</h4>
                                        <p>Maintaining work-life balance in remote positions starts with establishing clear boundaries—creating a dedicated workspace and setting defined working hours that you communicate to both colleagues and household members. Develop consistent daily routines with a proper morning ritual and end-of-day shutdown procedure to mentally transition between work and personal time. Take regular breaks using techniques like the Pomodoro method (25 minutes of work followed by 5-minute breaks) to prevent burnout. Schedule non-negotiable personal time for exercise, hobbies, and social connections. Use technology intentionally by turning off notifications after hours and using separate work/personal devices or profiles when possible. Finally, regularly assess your balance by checking in with yourself about stress levels and satisfaction, and be willing to adjust your boundaries and communicate needs to your employer when necessary.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

export default JobsInRemote;
