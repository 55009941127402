import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsInGermany extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('', 'Germany'))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Jobs in Germany: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in Germany? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-in-germany" />
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in Germany
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Find the best remote opportunities in Germany. From innovative startups to leading German enterprises.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myLocation="Germany" />

                    </div>
                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Remote Jobs in Germany</h2>
                                    <p>Common questions about remote work opportunities in Europe's largest economy</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What makes Germany unique for remote work opportunities?</h4>
                                        <p>Germany stands out for its strong tech ecosystem, particularly in hubs like Berlin, Munich, and Hamburg. The country's 'Work 4.0' initiative promotes digital transformation and remote work adoption. German companies are known for excellent work-life balance, comprehensive benefits, and strong worker protections. The country's robust infrastructure and digital connectivity support remote work efficiency. Germany's position as Europe's largest economy offers access to diverse industries, from automotive tech to renewable energy. Recent legislation (Mobile Arbeit Gesetz) has strengthened remote work rights and regulations, making it more accessible for both employees and employers.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the most in-demand remote skills in Germany?</h4>
                                        <p>Software development skills are highly valued, especially in Industry 4.0 technologies, artificial intelligence, and IoT. There's strong demand for professionals in cybersecurity, cloud computing, and data science, particularly in sectors like automotive and manufacturing tech. Digital transformation specialists are needed as German companies embrace Industrie 4.0. Sustainability and green tech expertise is increasingly important given Germany's focus on renewable energy. Project managers with agile methodologies experience and knowledge of German business practices are sought after. Language skills are valuable, with many roles requiring both German and English proficiency.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How do employment and benefits work for remote workers in Germany?</h4>
                                        <p>Remote workers in Germany typically enjoy strong labor protections under German employment law. Benefits include minimum 20 days paid vacation, health insurance, and parental leave. The German social security system requires both employer and employee contributions. Remote work agreements (Homeoffice-Vereinbarung) must comply with workplace safety and data protection regulations. Employers must provide necessary equipment or compensate for home office expenses. Working hours are regulated, with strict rules about overtime and rest periods. Freelancers (Freiberufler) must understand their obligations regarding taxes, health insurance, and social security contributions.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What should professionals know about German remote work culture?</h4>
                                        <p>German business culture values precision, punctuality, and direct communication, even in remote settings. Work-life balance is highly respected, with clear boundaries between work and personal time. Understanding German business etiquette and communication styles is important for successful collaboration. Remote workers should be familiar with GDPR and German data protection requirements. Professional development is highly valued, with many companies offering training opportunities. Networking through German tech communities, professional associations, and platforms like XING is valuable. Many successful remote workers participate in German tech conferences and industry events to stay connected. Understanding the role of works councils (Betriebsrat) in German companies can be important.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

                <Helmet>
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "What makes Germany unique for remote work opportunities?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Germany stands out for its strong tech ecosystem, particularly in hubs like Berlin, Munich, and Hamburg. The country's 'Work 4.0' initiative promotes digital transformation and remote work adoption. German companies are known for excellent work-life balance, comprehensive benefits, and strong worker protections. The country's robust infrastructure and digital connectivity support remote work efficiency. Germany's position as Europe's largest economy offers access to diverse industries, from automotive tech to renewable energy. Recent legislation (Mobile Arbeit Gesetz) has strengthened remote work rights and regulations, making it more accessible for both employees and employers."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the most in-demand remote skills in Germany?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Software development skills are highly valued, especially in Industry 4.0 technologies, artificial intelligence, and IoT. There's strong demand for professionals in cybersecurity, cloud computing, and data science, particularly in sectors like automotive and manufacturing tech. Digital transformation specialists are needed as German companies embrace Industrie 4.0. Sustainability and green tech expertise is increasingly important given Germany's focus on renewable energy. Project managers with agile methodologies experience and knowledge of German business practices are sought after. Language skills are valuable, with many roles requiring both German and English proficiency."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How do employment and benefits work for remote workers in Germany?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote workers in Germany typically enjoy strong labor protections under German employment law. Benefits include minimum 20 days paid vacation, health insurance, and parental leave. The German social security system requires both employer and employee contributions. Remote work agreements (Homeoffice-Vereinbarung) must comply with workplace safety and data protection regulations. Employers must provide necessary equipment or compensate for home office expenses. Working hours are regulated, with strict rules about overtime and rest periods. Freelancers (Freiberufler) must understand their obligations regarding taxes, health insurance, and social security contributions."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What should professionals know about German remote work culture?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "German business culture values precision, punctuality, and direct communication, even in remote settings. Work-life balance is highly respected, with clear boundaries between work and personal time. Understanding German business etiquette and communication styles is important for successful collaboration. Remote workers should be familiar with GDPR and German data protection requirements. Professional development is highly valued, with many companies offering training opportunities. Networking through German tech communities, professional associations, and platforms like XING is valuable. Many successful remote workers participate in German tech conferences and industry events to stay connected. Understanding the role of works councils (Betriebsrat) in German companies can be important."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>
            </div>
        );
    }
}

export default JobsInGermany;
