import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsFullStack extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('FullStack', ''))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Find Jobs in FullStack: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in FullStack? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-fullstack" />
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "What technologies should a full stack developer know in 2025?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Modern full stack developers should be proficient in JavaScript/TypeScript and at least one backend language (Python, Java, C#, Ruby, etc.). For frontend, knowledge of React, Angular, or Vue is essential, while backend expertise should include frameworks like Node.js/Express, Django, Spring Boot, or Rails. Database skills (SQL and NoSQL), API design, containerization (Docker), and cloud services (AWS/Azure/GCP) are increasingly important, as is understanding CI/CD pipelines and infrastructure as code."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How do companies evaluate full stack developers during interviews?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Companies typically evaluate full stack developers through multi-stage interviews that assess both technical skills and cultural fit. This often includes coding challenges covering algorithms and data structures, system design exercises to test architectural thinking, pair programming sessions to evaluate collaboration, and technical discussions about past projects. Many companies also look for problem-solving abilities, communication skills, and how candidates handle both frontend and backend scenarios under pressure."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the advantages of hiring remote full stack developers?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Hiring remote full stack developers offers several advantages: access to global talent without geographic limitations, cost efficiency (especially when hiring from regions with lower living costs), increased productivity due to fewer office distractions, and greater flexibility in scaling teams up or down. Full stack developers are particularly valuable in remote settings because they can work independently across the entire application stack, reducing communication overhead and dependencies between specialized teams."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can full stack developers balance breadth vs. depth of knowledge?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Full stack developers should aim for T-shaped knowledge: broad understanding across the entire stack with deep expertise in a few key areas. Focus on mastering fundamentals that transfer across technologies (data structures, algorithms, design patterns, API principles) while developing specialized knowledge in technologies you enjoy most. Stay current with industry trends but be selective about what you learn deeply. Contribute to diverse projects that strengthen both your primary skills and secondary competencies, and consider your career goals when deciding where to deepen your expertise."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in FullStack
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Find the best remote FullStack opportunities worldwide. From Junior Developers to Technical Architects.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myTitle="FullStack" />

                    </div>

                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Full Stack Jobs</h2>
                                    <p>Common questions about remote full stack development careers</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What technologies should a full stack developer know in 2025?</h4>
                                        <p>Modern full stack developers should be proficient in JavaScript/TypeScript and at least one backend language (Python, Java, C#, Ruby, etc.). For frontend, knowledge of React, Angular, or Vue is essential, while backend expertise should include frameworks like Node.js/Express, Django, Spring Boot, or Rails. Database skills (SQL and NoSQL), API design, containerization (Docker), and cloud services (AWS/Azure/GCP) are increasingly important, as is understanding CI/CD pipelines and infrastructure as code.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How do companies evaluate full stack developers during interviews?</h4>
                                        <p>Companies typically evaluate full stack developers through multi-stage interviews that assess both technical skills and cultural fit. This often includes coding challenges covering algorithms and data structures, system design exercises to test architectural thinking, pair programming sessions to evaluate collaboration, and technical discussions about past projects. Many companies also look for problem-solving abilities, communication skills, and how candidates handle both frontend and backend scenarios under pressure.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the advantages of hiring remote full stack developers?</h4>
                                        <p>Hiring remote full stack developers offers several advantages: access to global talent without geographic limitations, cost efficiency (especially when hiring from regions with lower living costs), increased productivity due to fewer office distractions, and greater flexibility in scaling teams up or down. Full stack developers are particularly valuable in remote settings because they can work independently across the entire application stack, reducing communication overhead and dependencies between specialized teams.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How can full stack developers balance breadth vs. depth of knowledge?</h4>
                                        <p>Full stack developers should aim for T-shaped knowledge: broad understanding across the entire stack with deep expertise in a few key areas. Focus on mastering fundamentals that transfer across technologies (data structures, algorithms, design patterns, API principles) while developing specialized knowledge in technologies you enjoy most. Stay current with industry trends but be selective about what you learn deeply. Contribute to diverse projects that strengthen both your primary skills and secondary competencies, and consider your career goals when deciding where to deepen your expertise.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Footer />
            </div>
        );
    }
}

export default JobsFullStack;
