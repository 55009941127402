import React, { Component } from "react";
import Footer from "../../Layout/Footer2";
import Header from "../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from '../Api';

import { Link } from "react-router-dom";
import ContentJobs from '../../Element/ContentJobs';
import Jobfindbox from "../../Element/Jobfindbox2";

const axios = require('axios');

class JobsInBoston extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('', 'Boston'))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Remote Jobs in Boston: Find Top Work Opportunities | OpenToWorkRemote</title>
                    <meta name="description" content="Browse remote jobs in Boston. Find top work-from-home opportunities with leading American companies across Boston. Apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-in-boston" />
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in Boston
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Career opportunities in Boston</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myLocation="Boston" />

                    </div>
                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Remote Jobs in Boston</h2>
                                    <p>Common questions about remote work opportunities in Boston's tech ecosystem</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What makes Boston unique for remote work opportunities?</h4>
                                        <p>Boston's unique ecosystem combines world-class universities (MIT, Harvard), a thriving biotech sector, and a robust tech startup scene. Remote workers benefit from access to Boston's innovation economy without the high cost of living in the city. The area's focus on education, healthcare, technology, and financial services creates diverse remote opportunities. Many Boston-based companies offer hybrid work models, allowing professionals to combine remote work with occasional in-person collaboration in one of America's leading innovation hubs.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the most in-demand remote jobs in Boston's market?</h4>
                                        <p>Boston's job market particularly values software engineers, especially in artificial intelligence and machine learning. Biotech and healthcare technology roles are abundant, including bioinformatics specialists and health tech product managers. Financial technology expertise is highly sought after, given Boston's strong financial services sector. There's also significant demand for data scientists, cybersecurity experts, and digital health professionals. The education technology sector, driven by the area's academic prominence, creates numerous remote opportunities in instructional design and educational content development.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What salary ranges can remote workers expect in Boston?</h4>
                                        <p>Remote workers in Boston-based companies typically enjoy competitive compensation reflecting the region's high-tech focus. Senior software engineers often command salaries ranging from $120,000 to $180,000+. Product managers and data scientists can expect ranges from $100,000 to $160,000. Healthcare technology specialists might see ranges from $90,000 to $150,000. While remote workers might receive slightly adjusted salaries based on their location, Boston companies often offer comprehensive benefits packages including health insurance, equity compensation, and professional development allowances.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How can professionals network in Boston's remote work environment?</h4>
                                        <p>Despite working remotely, professionals can connect with Boston's tech community through virtual meetups, online tech forums, and industry-specific Slack channels. Organizations like the Mass Technology Leadership Council offer virtual networking events. Many Boston startups host virtual lunch-and-learns and technical workshops. LinkedIn groups focused on Boston's tech scene provide valuable connections. Additionally, participating in online communities centered around specific technologies or industries prevalent in Boston (like biotech or edtech) can lead to job opportunities and professional growth.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

                <Helmet>
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "What makes Boston unique for remote work opportunities?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Boston's unique ecosystem combines world-class universities (MIT, Harvard), a thriving biotech sector, and a robust tech startup scene. Remote workers benefit from access to Boston's innovation economy without the high cost of living in the city. The area's focus on education, healthcare, technology, and financial services creates diverse remote opportunities. Many Boston-based companies offer hybrid work models, allowing professionals to combine remote work with occasional in-person collaboration in one of America's leading innovation hubs."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the most in-demand remote jobs in Boston's market?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Boston's job market particularly values software engineers, especially in artificial intelligence and machine learning. Biotech and healthcare technology roles are abundant, including bioinformatics specialists and health tech product managers. Financial technology expertise is highly sought after, given Boston's strong financial services sector. There's also significant demand for data scientists, cybersecurity experts, and digital health professionals. The education technology sector, driven by the area's academic prominence, creates numerous remote opportunities in instructional design and educational content development."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What salary ranges can remote workers expect in Boston?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote workers in Boston-based companies typically enjoy competitive compensation reflecting the region's high-tech focus. Senior software engineers often command salaries ranging from $120,000 to $180,000+. Product managers and data scientists can expect ranges from $100,000 to $160,000. Healthcare technology specialists might see ranges from $90,000 to $150,000. While remote workers might receive slightly adjusted salaries based on their location, Boston companies often offer comprehensive benefits packages including health insurance, equity compensation, and professional development allowances."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can professionals network in Boston's remote work environment?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Despite working remotely, professionals can connect with Boston's tech community through virtual meetups, online tech forums, and industry-specific Slack channels. Organizations like the Mass Technology Leadership Council offer virtual networking events. Many Boston startups host virtual lunch-and-learns and technical workshops. LinkedIn groups focused on Boston's tech scene provide valuable connections. Additionally, participating in online communities centered around specific technologies or industries prevalent in Boston (like biotech or edtech) can lead to job opportunities and professional growth."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>
            </div>
        );
    }
}

export default JobsInBoston;
