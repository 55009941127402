import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsCustomerSuccess extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('Customer Success', ''))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Find Jobs in Customer Success: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in Customer Success? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-customer-success" />
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "What skills are most important for Customer Success roles?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "The most important skills for Customer Success roles include excellent communication (both written and verbal), empathy, problem-solving abilities, and product knowledge. Technical skills vary by industry, but proficiency with CRM software like Salesforce, Gainsight, or HubSpot is often required. Additionally, data analysis capabilities, project management skills, and the ability to build strong relationships are highly valued. Customer Success professionals should also be proactive, patient, and able to translate technical concepts into language that customers can understand."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How does Customer Success differ from Customer Support?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "While Customer Support is typically reactive and focused on resolving immediate issues, Customer Success is proactive and strategic, focused on helping customers achieve their long-term goals with the product. Support teams handle tickets and troubleshoot problems, while Success teams build relationships, drive product adoption, and ensure customers receive value from their purchase. Customer Success Managers often work with accounts throughout their lifecycle, from onboarding to renewal, and are usually measured on metrics like retention, expansion revenue, and customer health scores rather than ticket resolution times."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What metrics are most important for Customer Success professionals?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Key metrics for Customer Success professionals include customer retention rate, net revenue retention, customer lifetime value (CLV), net promoter score (NPS), customer satisfaction (CSAT), product adoption rates, and time-to-value. Many teams also track customer health scores (combining usage, engagement, and satisfaction data), expansion revenue, and churn risk indicators. For remote Customer Success roles, additional metrics might include response times, meeting frequency with key accounts, and successful completion of customer business reviews (QBRs). Effective CS professionals connect their activities directly to business outcomes and revenue impact."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can Customer Success professionals effectively work remotely?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Successful remote Customer Success professionals establish clear communication channels and set expectations with clients about availability and response times. They leverage video conferencing tools to maintain face-to-face connections and use screen sharing for effective product demonstrations and training. Documentation becomes even more critical in remote settings—keeping detailed notes of customer interactions, preferences, and goals in a CRM system. Creating scalable processes through recorded training sessions, knowledge bases, and automated check-ins helps manage multiple accounts efficiently. Finally, remote CS professionals should proactively schedule regular touchpoints with customers to maintain relationships despite the physical distance."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in Customer Success
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Spot your dream job today. #OpenToWorkRemote</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myTitle="Customer Success" />

                    </div>

                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Customer Success Jobs</h2>
                                    <p>Common questions about remote Customer Success careers</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What skills are most important for Customer Success roles?</h4>
                                        <p>The most important skills for Customer Success roles include excellent communication (both written and verbal), empathy, problem-solving abilities, and product knowledge. Technical skills vary by industry, but proficiency with CRM software like Salesforce, Gainsight, or HubSpot is often required. Additionally, data analysis capabilities, project management skills, and the ability to build strong relationships are highly valued. Customer Success professionals should also be proactive, patient, and able to translate technical concepts into language that customers can understand.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How does Customer Success differ from Customer Support?</h4>
                                        <p>While Customer Support is typically reactive and focused on resolving immediate issues, Customer Success is proactive and strategic, focused on helping customers achieve their long-term goals with the product. Support teams handle tickets and troubleshoot problems, while Success teams build relationships, drive product adoption, and ensure customers receive value from their purchase. Customer Success Managers often work with accounts throughout their lifecycle, from onboarding to renewal, and are usually measured on metrics like retention, expansion revenue, and customer health scores rather than ticket resolution times.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What metrics are most important for Customer Success professionals?</h4>
                                        <p>Key metrics for Customer Success professionals include customer retention rate, net revenue retention, customer lifetime value (CLV), net promoter score (NPS), customer satisfaction (CSAT), product adoption rates, and time-to-value. Many teams also track customer health scores (combining usage, engagement, and satisfaction data), expansion revenue, and churn risk indicators. For remote Customer Success roles, additional metrics might include response times, meeting frequency with key accounts, and successful completion of customer business reviews (QBRs). Effective CS professionals connect their activities directly to business outcomes and revenue impact.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How can Customer Success professionals effectively work remotely?</h4>
                                        <p>Successful remote Customer Success professionals establish clear communication channels and set expectations with clients about availability and response times. They leverage video conferencing tools to maintain face-to-face connections and use screen sharing for effective product demonstrations and training. Documentation becomes even more critical in remote settings—keeping detailed notes of customer interactions, preferences, and goals in a CRM system. Creating scalable processes through recorded training sessions, knowledge bases, and automated check-ins helps manage multiple accounts efficiently. Finally, remote CS professionals should proactively schedule regular touchpoints with customers to maintain relationships despite the physical distance.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

export default JobsCustomerSuccess;
