import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsInBrazil extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('', 'Brazil'))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Jobs in Brazil: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in Brazil? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-in-brazil" />
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in Brazil
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Find the best remote opportunities in Brazil. From emerging startups to established Brazilian companies.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myLocation="Brazil" />

                    </div>
                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Remote Jobs in Brazil</h2>
                                    <p>Common questions about remote work opportunities for professionals in Brazil</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the advantages of remote work for Brazilian professionals?</h4>
                                        <p>Remote work offers Brazilian professionals access to international opportunities and foreign currency earnings, providing financial stability and higher earning potential. It eliminates commuting time in large cities like São Paulo and Rio de Janeiro, improving quality of life. Remote roles often provide flexibility to work across different time zones, particularly advantageous for collaborating with North American companies. Additionally, professionals can work from any location in Brazil, including emerging tech hubs like Florianópolis and Recife, while maintaining competitive international salaries.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the most in-demand remote skills in Brazil?</h4>
                                        <p>Brazil's tech talent is highly sought after, particularly in software development with expertise in Java, Python, React, and Node.js. There's strong demand for professionals in emerging technologies like artificial intelligence and blockchain. Digital marketing specialists with experience in Latin American markets are valuable for global companies. Product managers and UX/UI designers from Brazil are appreciated for their innovative approaches and understanding of diverse markets. Additionally, Brazilian professionals with strong English skills are particularly valued for roles in customer success and technical support serving international clients.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How do payment and contracts work for remote workers in Brazil?</h4>
                                        <p>Brazilian remote workers typically receive payments through international transfer services like Wise, Payoneer, or bank transfers. Many work as "PJ" (Pessoa Jurídica), operating through their own companies for tax efficiency. International companies often employ Brazilians through Employer of Record (EOR) services that handle local compliance and payroll. Some professionals use digital payment platforms or cryptocurrency for international transactions. Understanding tax obligations and choosing the right payment structure is crucial, as is compliance with Brazilian labor laws when working with local companies.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the legal considerations for remote work in Brazil?</h4>
                                        <p>Remote workers in Brazil need to understand their employment classification (CLT vs. PJ) and associated rights and obligations. Those working as PJ must register their company, manage tax declarations, and comply with local business regulations. The recent remote work legislation (Lei do Teletrabalho) establishes rights and responsibilities for remote employees under CLT. Workers should be aware of data protection requirements under LGPD (Brazil's GDPR equivalent) when handling international data. It's advisable to consult with legal and accounting professionals to ensure proper compliance with Brazilian labor and tax laws.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

                <Helmet>
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "What are the advantages of remote work for Brazilian professionals?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote work offers Brazilian professionals access to international opportunities and foreign currency earnings, providing financial stability and higher earning potential. It eliminates commuting time in large cities like São Paulo and Rio de Janeiro, improving quality of life. Remote roles often provide flexibility to work across different time zones, particularly advantageous for collaborating with North American companies. Additionally, professionals can work from any location in Brazil, including emerging tech hubs like Florianópolis and Recife, while maintaining competitive international salaries."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the most in-demand remote skills in Brazil?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Brazil's tech talent is highly sought after, particularly in software development with expertise in Java, Python, React, and Node.js. There's strong demand for professionals in emerging technologies like artificial intelligence and blockchain. Digital marketing specialists with experience in Latin American markets are valuable for global companies. Product managers and UX/UI designers from Brazil are appreciated for their innovative approaches and understanding of diverse markets. Additionally, Brazilian professionals with strong English skills are particularly valued for roles in customer success and technical support serving international clients."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How do payment and contracts work for remote workers in Brazil?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Brazilian remote workers typically receive payments through international transfer services like Wise, Payoneer, or bank transfers. Many work as 'PJ' (Pessoa Jurídica), operating through their own companies for tax efficiency. International companies often employ Brazilians through Employer of Record (EOR) services that handle local compliance and payroll. Some professionals use digital payment platforms or cryptocurrency for international transactions. Understanding tax obligations and choosing the right payment structure is crucial, as is compliance with Brazilian labor laws when working with local companies."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the legal considerations for remote work in Brazil?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote workers in Brazil need to understand their employment classification (CLT vs. PJ) and associated rights and obligations. Those working as PJ must register their company, manage tax declarations, and comply with local business regulations. The recent remote work legislation (Lei do Teletrabalho) establishes rights and responsibilities for remote employees under CLT. Workers should be aware of data protection requirements under LGPD (Brazil's GDPR equivalent) when handling international data. It's advisable to consult with legal and accounting professionals to ensure proper compliance with Brazilian labor and tax laws."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>
            </div>
        );
    }
}

export default JobsInBrazil;
