import React, { Component } from "react";
import Footer from "../../Layout/Footer2";
import Header from "../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from '../Api';

import { Link } from "react-router-dom";
import ContentJobs from '../../Element/ContentJobs';
import Jobfindbox from "../../Element/Jobfindbox2";

const axios = require('axios');

class JobsInLondon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('', 'London'))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Remote Jobs in London: Find Top Work Opportunities | OpenToWorkRemote</title>
                    <meta name="description" content="Browse remote jobs in London. Find top work-from-home opportunities with leading British companies across London, England and more. Apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-in-london" />
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in London
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Discover top remote jobs in London. Work with leading companies from home.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myLocation="London" />

                    </div>
                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Remote Jobs in London</h2>
                                    <p>Common questions about remote work opportunities in the UK's tech capital</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">Why is London a prime location for remote work opportunities?</h4>
                                        <p>London remains one of Europe's leading tech hubs, with areas like Silicon Roundabout and Tech City hosting numerous innovative companies. The city's position as a global financial center creates diverse opportunities in fintech and digital services. Post-Brexit, London continues to attract international talent with its flexible work policies and strong startup ecosystem. The city's time zone allows collaboration with both European and American markets. London-based companies often offer competitive remote work packages, including hybrid options. The presence of major tech companies, accelerators, and venture capital firms creates a dynamic environment for remote workers connected to London's ecosystem.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the most in-demand remote skills in London?</h4>
                                        <p>Software development skills are highly valued, particularly in fintech, blockchain, and artificial intelligence. London's strong financial sector creates demand for professionals in cybersecurity, data science, and regulatory technology (RegTech). The city's creative industries need UX/UI designers and digital content specialists. Project managers with experience in agile methodologies and distributed teams are consistently sought after. There's growing demand for professionals in emerging technologies like quantum computing and green tech. Digital marketing experts who understand both UK and international markets are valuable. The startup ecosystem creates opportunities for full-stack developers and product managers with entrepreneurial mindsets.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How do employment and contracts work for remote workers in London?</h4>
                                        <p>Remote workers in London typically operate either as employees or contractors (often through limited companies or umbrella companies). UK employment law provides strong protections, including statutory benefits like holiday pay and pension contributions. IR35 legislation affects how contractors work with larger companies. Remote workers should understand their rights under UK flexible working regulations. Many companies offer hybrid arrangements allowing occasional office visits to London headquarters. Tax considerations vary between PAYE employment and self-employment structures. Benefits often include private healthcare, stock options, and professional development allowances. Post-Brexit changes affect how UK-based remote workers engage with EU companies.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How can professionals succeed in London's remote work environment?</h4>
                                        <p>Success in London's remote work landscape requires staying connected with the city's tech community through organizations like Tech London Advocates and Silicon Roundabout meetups. Understanding both UK business practices and international work cultures is important. Many remote workers benefit from London's coworking spaces and tech hubs for occasional networking. Professional development through London-based tech academies and online learning platforms enhances career prospects. Networking at London Tech Week and other major industry events, even virtually, is valuable. Understanding UK data protection requirements (UK GDPR) and financial regulations is crucial for many roles. Many successful remote workers maintain connections with London's innovation ecosystem while working flexibly from any location.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

                <Helmet>
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "Why is London a prime location for remote work opportunities?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "London remains one of Europe's leading tech hubs, with areas like Silicon Roundabout and Tech City hosting numerous innovative companies. The city's position as a global financial center creates diverse opportunities in fintech and digital services. Post-Brexit, London continues to attract international talent with its flexible work policies and strong startup ecosystem. The city's time zone allows collaboration with both European and American markets. London-based companies often offer competitive remote work packages, including hybrid options. The presence of major tech companies, accelerators, and venture capital firms creates a dynamic environment for remote workers connected to London's ecosystem."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the most in-demand remote skills in London?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Software development skills are highly valued, particularly in fintech, blockchain, and artificial intelligence. London's strong financial sector creates demand for professionals in cybersecurity, data science, and regulatory technology (RegTech). The city's creative industries need UX/UI designers and digital content specialists. Project managers with experience in agile methodologies and distributed teams are consistently sought after. There's growing demand for professionals in emerging technologies like quantum computing and green tech. Digital marketing experts who understand both UK and international markets are valuable. The startup ecosystem creates opportunities for full-stack developers and product managers with entrepreneurial mindsets."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How do employment and contracts work for remote workers in London?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote workers in London typically operate either as employees or contractors (often through limited companies or umbrella companies). UK employment law provides strong protections, including statutory benefits like holiday pay and pension contributions. IR35 legislation affects how contractors work with larger companies. Remote workers should understand their rights under UK flexible working regulations. Many companies offer hybrid arrangements allowing occasional office visits to London headquarters. Tax considerations vary between PAYE employment and self-employment structures. Benefits often include private healthcare, stock options, and professional development allowances. Post-Brexit changes affect how UK-based remote workers engage with EU companies."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can professionals succeed in London's remote work environment?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Success in London's remote work landscape requires staying connected with the city's tech community through organizations like Tech London Advocates and Silicon Roundabout meetups. Understanding both UK business practices and international work cultures is important. Many remote workers benefit from London's coworking spaces and tech hubs for occasional networking. Professional development through London-based tech academies and online learning platforms enhances career prospects. Networking at London Tech Week and other major industry events, even virtually, is valuable. Understanding UK data protection requirements (UK GDPR) and financial regulations is crucial for many roles. Many successful remote workers maintain connections with London's innovation ecosystem while working flexibly from any location."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>
            </div>
        );
    }
}

export default JobsInLondon;
