import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsInAustralia extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('', 'Australia'))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Jobs in Australia: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in Australia? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-in-australia" />
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "What are the benefits of remote work in Australia?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote work in Australia offers numerous advantages including better work-life balance, reduced commuting time and costs, especially in major cities like Sydney and Melbourne. It provides access to opportunities across different time zones, particularly with Asian and American markets. Remote workers often enjoy flexible schedules, increased productivity, and the ability to work from any location in Australia, from coastal towns to regional areas, while maintaining competitive salaries from global companies."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the most in-demand remote jobs in Australia?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Australia has a strong demand for remote workers in technology roles such as software development, cloud architecture, and cybersecurity. Digital marketing specialists, data analysts, and UX/UI designers are highly sought after. The growing startup ecosystem also creates opportunities for product managers and digital project managers. Additionally, there's significant demand for remote customer success managers, technical writers, and financial technology professionals, particularly those with experience in Australia's regulatory environment."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the legal considerations for remote work in Australia?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote workers in Australia need to understand their employment status (employee vs. contractor) and associated rights. Employees are covered by the Fair Work Act, including minimum wage requirements, leave entitlements, and superannuation. Contractors must manage their own tax obligations, insurance, and superannuation. All remote workers should have clear written agreements covering work hours, equipment provisions, and data security requirements. Companies employing remote workers must comply with workplace health and safety regulations, even for home-based work."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can Australians prepare for remote work success?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Success in remote work requires a strong home office setup with reliable high-speed internet and appropriate technology. Developing strong digital communication skills and familiarity with collaboration tools is essential. Time management and self-discipline are crucial, especially when working across different time zones. Building a professional network through online communities and industry groups can help with job opportunities and career development. Keeping skills updated through online courses and certifications is also important in the rapidly evolving remote work landscape."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in Australia
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Find the best remote opportunities in Australia. From innovative startups to leading Australian enterprises.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myLocation="Australia" />

                    </div>
                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Remote Jobs in Australia</h2>
                                    <p>Common questions about remote work opportunities for professionals in Australia</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the benefits of remote work in Australia?</h4>
                                        <p>Remote work in Australia offers numerous advantages including better work-life balance, reduced commuting time and costs, especially in major cities like Sydney and Melbourne. It provides access to opportunities across different time zones, particularly with Asian and American markets. Remote workers often enjoy flexible schedules, increased productivity, and the ability to work from any location in Australia, from coastal towns to regional areas, while maintaining competitive salaries from global companies.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the most in-demand remote jobs in Australia?</h4>
                                        <p>Australia has a strong demand for remote workers in technology roles such as software development, cloud architecture, and cybersecurity. Digital marketing specialists, data analysts, and UX/UI designers are highly sought after. The growing startup ecosystem also creates opportunities for product managers and digital project managers. Additionally, there's significant demand for remote customer success managers, technical writers, and financial technology professionals, particularly those with experience in Australia's regulatory environment.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the legal considerations for remote work in Australia?</h4>
                                        <p>Remote workers in Australia need to understand their employment status (employee vs. contractor) and associated rights. Employees are covered by the Fair Work Act, including minimum wage requirements, leave entitlements, and superannuation. Contractors must manage their own tax obligations, insurance, and superannuation. All remote workers should have clear written agreements covering work hours, equipment provisions, and data security requirements. Companies employing remote workers must comply with workplace health and safety regulations, even for home-based work.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How can Australians prepare for remote work success?</h4>
                                        <p>Success in remote work requires a strong home office setup with reliable high-speed internet and appropriate technology. Developing strong digital communication skills and familiarity with collaboration tools is essential. Time management and self-discipline are crucial, especially when working across different time zones. Building a professional network through online communities and industry groups can help with job opportunities and career development. Keeping skills updated through online courses and certifications is also important in the rapidly evolving remote work landscape.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

export default JobsInAustralia;
