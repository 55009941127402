import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsInLatam extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('', 'LATAM'))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Jobs in LATAM: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in LATAM? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-in-latam" />
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in LATAM
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Find the best remote opportunities in Latin America. From startups to enterprise companies across all industries.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myLocation="LATAM" />

                    </div>
                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Remote Jobs in Latin America</h2>
                                    <p>Common questions about remote work opportunities across Latin American countries</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">Why is Latin America becoming a global remote work hub?</h4>
                                        <p>Latin America has emerged as a leading remote work destination due to its growing tech ecosystem and talented workforce. The region's time zone alignment with North America makes real-time collaboration efficient. Major tech hubs in cities like São Paulo, Mexico City, Buenos Aires, and Bogotá offer diverse opportunities. The region's competitive cost structure and high-quality education system produces skilled professionals in technology and digital services. Growing investment in digital infrastructure and government support for tech sectors across LATAM countries creates a robust environment for remote work. Additionally, the region's cultural affinity with both North American and European markets makes it attractive for global companies.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the most in-demand remote skills in Latin America?</h4>
                                        <p>Software development skills are highly valued, particularly in web development, mobile apps, and cloud technologies. The region's growing fintech sector creates strong demand for professionals in financial technology and digital payments. There's significant need for data scientists and AI specialists as companies embrace digital transformation. UX/UI designers from LATAM are valued for their creativity and understanding of diverse markets. Digital marketing specialists who can navigate both regional and global markets are in high demand. Bilingual and trilingual professionals (Spanish/Portuguese/English) are particularly sought after for roles involving international collaboration. Project managers with experience in agile methodologies and cross-cultural team management are consistently needed.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How do payments and contracts work for remote workers in LATAM?</h4>
                                        <p>Remote workers in Latin America typically receive payments through international platforms like Wise, Payoneer, or cryptocurrency. Many professionals work as independent contractors or through local entities, depending on their country's regulations. Understanding local tax obligations and social security requirements is crucial. Payment structures often account for currency fluctuations and international transfer fees. Different countries have varying requirements for remote work contracts and worker classification. Many companies use Employer of Record (EOR) services to handle compliant hiring across LATAM countries. Remote workers should be familiar with their local labor laws and international payment regulations. Some countries offer special visa categories or simplified tax regimes for remote workers.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How can professionals succeed in LATAM's remote work environment?</h4>
                                        <p>Success in Latin America's remote work landscape requires strong digital skills and proficiency in collaboration tools. Networking through regional tech communities like Platzi, Digital House, and local tech meetups is valuable. Many professionals benefit from participating in LATAM's growing startup ecosystem and tech events. Maintaining strong English skills is crucial for international opportunities. Understanding both regional business practices and global work cultures is important. Many successful remote workers join coworking spaces and tech hubs across major LATAM cities for networking and professional development. Staying updated with regional tech trends and participating in online communities helps build professional networks. Taking advantage of educational platforms and bootcamps focused on the LATAM market can enhance career prospects.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

                <Helmet>
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "Why is Latin America becoming a global remote work hub?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Latin America has emerged as a leading remote work destination due to its growing tech ecosystem and talented workforce. The region's time zone alignment with North America makes real-time collaboration efficient. Major tech hubs in cities like São Paulo, Mexico City, Buenos Aires, and Bogotá offer diverse opportunities. The region's competitive cost structure and high-quality education system produces skilled professionals in technology and digital services. Growing investment in digital infrastructure and government support for tech sectors across LATAM countries creates a robust environment for remote work. Additionally, the region's cultural affinity with both North American and European markets makes it attractive for global companies."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the most in-demand remote skills in Latin America?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Software development skills are highly valued, particularly in web development, mobile apps, and cloud technologies. The region's growing fintech sector creates strong demand for professionals in financial technology and digital payments. There's significant need for data scientists and AI specialists as companies embrace digital transformation. UX/UI designers from LATAM are valued for their creativity and understanding of diverse markets. Digital marketing specialists who can navigate both regional and global markets are in high demand. Bilingual and trilingual professionals (Spanish/Portuguese/English) are particularly sought after for roles involving international collaboration. Project managers with experience in agile methodologies and cross-cultural team management are consistently needed."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How do payments and contracts work for remote workers in LATAM?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote workers in Latin America typically receive payments through international platforms like Wise, Payoneer, or cryptocurrency. Many professionals work as independent contractors or through local entities, depending on their country's regulations. Understanding local tax obligations and social security requirements is crucial. Payment structures often account for currency fluctuations and international transfer fees. Different countries have varying requirements for remote work contracts and worker classification. Many companies use Employer of Record (EOR) services to handle compliant hiring across LATAM countries. Remote workers should be familiar with their local labor laws and international payment regulations. Some countries offer special visa categories or simplified tax regimes for remote workers."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can professionals succeed in LATAM's remote work environment?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Success in Latin America's remote work landscape requires strong digital skills and proficiency in collaboration tools. Networking through regional tech communities like Platzi, Digital House, and local tech meetups is valuable. Many professionals benefit from participating in LATAM's growing startup ecosystem and tech events. Maintaining strong English skills is crucial for international opportunities. Understanding both regional business practices and global work cultures is important. Many successful remote workers join coworking spaces and tech hubs across major LATAM cities for networking and professional development. Staying updated with regional tech trends and participating in online communities helps build professional networks. Taking advantage of educational platforms and bootcamps focused on the LATAM market can enhance career prospects."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>
            </div>
        );
    }
}

export default JobsInLatam;
