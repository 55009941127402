import React, { Component } from "react";
//import { Link } from "react-router-dom";
import Jobfindbox from "./../Element/Jobfindbox2";
import Footer from "./../Layout/Footer2";
import Header from "./../Layout/Header";
import Jobcategories from './../Element/Jobcategories';
import Featureblog from './../Element/Featureblog';
// import UnlockJobs from './../Element/UnlockJobs';
import Sponsors from './../Element/Sponsors';
import { Helmet } from 'react-helmet';
import ContentJobs from './../Element/ContentJobs';
import { getUrl, getHeaders } from './Api';
import './browsejobgrid.css';

const axios = require('axios');

class Browsejobgrid extends Component {
    constructor(props) {
        super(props);

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        this.state = {
            jobs: [],
            total: '',
            myparams: params,
            isLoading: true
        };

        // Mover las listas aquí, fuera del render
        this.validJobTitles = [
            "Backend", "Frontend", "FullStack", "Marketing", "Sales", "QA", "HR", 
            "Sourcer", "Recruiter", "Talent Acquisition", "Customer Success", 
            "Customer Success Manager", "Customer Support", "Customer Service", 
            "Help Desk", "Call Center", "Head of Product", "Product Manager", 
            "Product Owner", "Product Designer", "Product Engineer", "Product Analyst", 
            "Project Manager", "Program Manager", "Senior Software Engineer", 
            "Solutions Engineer", "Technical Support", "Community Manager", 
            "Social Media", "Graphic Designer", "Designer", "Finance", "Data Entry", 
            "Data Analyst", "Data Engineer", "Data Architect", "Data Scientist", 
            "Scrum Master", "Security Engineer", "Automation", "Game Tester", "SDET", 
            "Part-Time", "SEO", "UI/UX", "DevOps", "SRE", "Site Reliability Engineer", 
            "System Administrator", "Infrastructure Engineer", "Network Engineer", 
            "Information Security", "Cybersecurity", "DevSecOps", "Release Engineer", 
            "Release Manager", "Human Resources", "Administrator", "Executive Assistant", 
            "Virtual Assistant", "Sales Engineer", "Sales Development", "Copywriter", 
            "Content Writer", "Technical Writer", "Underwriting", "Platform Engineer", 
            "Business Development", "Business Operations", "Business Analyst", 
            "Business Intelligence", "Operations Manager", "Engineering Manager", 
            "Electrical Engineer", "Mechanical Engineer", "Support Engineer", 
            "Account Manager", "Account Executive", "Accountant", "Treasury", "Payroll", 
            "Paid Media", "Compensation", "Consultant", "Video Editor", "Animator", 
            "Artist", "Auditor", "Attorney", "Lawyer", "Counsel", "Legal", "Risk", 
            "VP", "Jr", "Junior", "Supply Chain", "Logistics", "Freelance", "Developer", 
            "Web Developer", "Mobile", "iOS", "Android", "PHP", "Laravel", "Symfony", 
            "Wordpress", "Java", "JavaScript", "TypeScript", "React", "Angular", 
            "Vue.js", "Python", "Django", "Node.js", ".NET", "Golang", "C++", "C#", 
            "Rust", "Scala", "Ruby", "Ruby on Rails", "Blockchain", "Solidity", 
            "Web3", "AI", "AWS", "Tech Lead", "Power BI", "Salesforce", "Machine Learning"
        ];

        this.validLocations = [
            "Remote", "United States", "Argentina", "Armenia", "Australia", "Austria", 
            "Belgium", "Brazil", "Bulgaria", "Canada", "Chile", "Colombia", "Costa Rica", 
            "Croatia", "Cyprus", "Czechia", "Denmark", "Ecuador", "El Salvador", "Egypt", 
            "Estonia", "Ethiopia", "Europe", "Finland", "France", "Gabon", "Germany", 
            "Greece", "Hungary", "India", "Indonesia", "Ireland", "Israel", "Italy", 
            "Japan", "LATAM", "Latvia", "Lebanon", "Liberia", "Lithuania", "Luxembourg", 
            "Malaysia", "Mexico", "Morocco", "Namibia", "Netherlands", "New Zealand", 
            "Nicaragua", "Nigeria", "Norway", "Pakistan", "Peru", "Philippines", "Poland", 
            "Portugal", "Romania", "Serbia", "Singapore", "Slovakia", "South Africa", 
            "Spain", "Sweden", "Switzerland", "Thailand", "Tunisia", "Turkey", 
            "United Kingdom", "USA", "Ukraine", "Uruguay", "Toronto", "Ohio", "London"
        ];
    }

    componentDidMount() {
        // Mostrar skeleton inmediatamente
        this.setState({ isLoading: true });

        // Diferir la carga inicial de datos
        if ('requestIdleCallback' in window) {
            window.requestIdleCallback(() => this.jobsList());
        } else {
            setTimeout(() => this.jobsList(), 100);
        }
    }

    jobsList() {
        this.setState({ isLoading: true });
        
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        const options = {
            method: "GET",
            url: getUrl(),
            params: {
                perPage: params.perPage || 20,
                page: params.page,
                title: params.title,
                location: params.location,
                salaryMin: params.salaryMin,
                tag: params.tag,
                company: params.company,
                source: params.source
            },
            headers: getHeaders()
        };

        axios.request(options)
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({
                    isLoading: false,
                    error: 'Failed to load jobs. Please try again later.'
                });
            });
    }

    sanitizeParam(param) {
        if (!param) return '';
        try {
            param = decodeURIComponent(param);
        } catch (e) {
            console.error("Error decoding URL parameter:", e);
        }
        return param.split(/[?&=]/)[0].trim();
    }

    validateParams(title, location) {
        const sanitizedTitle = this.sanitizeParam(title);
        const sanitizedLocation = this.sanitizeParam(location);

        const isValidTitle = this.validJobTitles
            .map(t => t.toLowerCase())
            .includes(sanitizedTitle.toLowerCase());
        
        const isValidLocation = this.validLocations
            .map(l => l.toLowerCase())
            .includes(sanitizedLocation.toLowerCase()) ||
            (sanitizedLocation.toLowerCase() === 'remote.' && 'remote');

        return {
            validTitle: isValidTitle ? sanitizedTitle : '',
            validLocation: isValidLocation ? 
                (sanitizedLocation.toLowerCase() === 'remote.' ? 'Remote' : sanitizedLocation) 
                : ''
        };
    }

    render() {
        var { jobs, total, page, pages, prevPage, nextPage, myparams, isLoading } = this.state;

        let showTopSearch = true;
        if (myparams.title || myparams.location || myparams.salaryMin || myparams.page) {
            showTopSearch = false;
        }

        // Reemplazar toda la lógica de validación con esta línea
        const { validTitle, validLocation } = this.validateParams(myparams.title, myparams.location);

        // El resto de la lógica de customTitle y customDescription permanece igual
        let customTitle = 'Find Your Perfect Remote Job';
        let customDescription = 'Ready to work from anywhere? Find your dream job!';

        if (validTitle || validLocation) {
            if (validTitle && validLocation) {
                if (validLocation.toLowerCase() === 'remote') {
                    customTitle = `${validTitle} Remote Jobs`;
                    customDescription = `Discover remote ${validTitle} positions worldwide`;
                } else {
                    customTitle = `${validTitle} Jobs in ${validLocation}`;
                    customDescription = `Find the best remote ${validTitle} opportunities in ${validLocation}`;
                }
            } else if (validTitle) {
                customTitle = `${validTitle} Jobs`;
                customDescription = `Discover remote ${validTitle} positions`;
            } else if (validLocation) {
                if (validLocation.toLowerCase() === 'remote') {
                    customTitle = 'Remote Jobs Worldwide';
                    customDescription = 'Find the best remote work opportunities worldwide';
                } else {
                    customTitle = `Jobs in ${validLocation}`;
                    customDescription = `Find remote work opportunities in ${validLocation}`;
                }
            }
        }

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>{customTitle} | OpenToWorkRemote</title>
                    <meta 
                        name="description" 
                        content={customDescription} 
                    />
                    <meta 
                        name="keywords" 
                        content="remote jobs, work from home, remote work, remote career, remote opportunities, remote positions" 
                    />
                    <link rel="canonical" href="https://www.opentoworkremote.com/" />
                </Helmet>

                <div className="page-content bg-white">
                    <div className="dez-bnr-inr bg-dark">
                        <div className="container">
                            <div className="dez-bnr-inr-entry text-center">
                                <a href="/" className="banner-link" rel="noopener noreferrer">
                                    <h1 className="text-white display-4 mb-4">
                                        {customTitle}
                                    </h1>
                                </a>
                                <div className="breadcrumb-row">
                                    <ul className="banner-subtitle">
                                        <li className="lead text-light">
                                            {customDescription}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Jobfindbox />
                    {showTopSearch && (
                    <div className="section-full job-categories content-inner-2 bg-white">
                        <div className="container">
                            <div className="section-head text-center">
                                <h2 className="m-b5">
                                    <span className="text-primary">Explore</span> Top Remote Searches
                                </h2>
                                <div className="separator">
                                    <span className="separator-line"></span>
                                </div>
                                <h5 className="fw4 m-t15">Find jobs categorized perfectly for you!</h5>
                            </div>
                            <Jobcategories />
                        </div>
                    </div>
                    )}

                    <ContentJobs 
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />
                </div>

                {showTopSearch && (
                    <Featureblog />
                )}

                {showTopSearch && (
                    <Sponsors />
                )}

                {/* {showTopSearch && (
                    <UnlockJobs />
                )} */}

                <Footer />
            </div>
        );
    }
}

export default Browsejobgrid;
