/* eslint-disable jsx-a11y/accessible-emoji */
import React, {Component} from 'react';
import '../../css/select2-custom.css';

class Jobfindbox2 extends Component {

    state = {
        title: '',
        tag: '',
        location: '',
        salaryMin: ''
    }

    // Definir las listas de páginas específicas como constantes de clase
    static SPECIFIC_TITLE_PAGES = [
        "Account Executive",
        "AWS",
        "Backend",
        "Customer Success",
        "Django",
        "Frontend",
        "FullStack",
        "Golang",
        "JavaScript",
        "Laravel",
        "Marketing",
        "PHP",
        "Python",
        "React",
        "Ruby",
        "Sales",
        "Software Engineer",
    ];

    static SPECIFIC_LOCATION_PAGES = [
        "Argentina",
        "Australia",
        "Brazil",
        "Boston",
        "Canada",
        "Chile",
        "Colombia",
        "Costa Rica",
        "Europe",
        "Florida",
        "Germany",
        "Ireland",
        "LATAM",
        "London",
        "Malaysia",
        "Mexico",
        "New York",
        "New York City",
        "Ohio",
        "Peru",
        "Portugal",
        "Spain",
        "South Africa",
        "Toronto",
        "United Kingdom",
        "Uruguay",
    ];

    changeTitle = (e) => {
        this.setState({
            title: e.target.value,
        });
        
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleSearch();
        }
    }

    changeTag = (e) => {
        this.setState({
            tag: e.target.value,
        });
    }

    changeLocation = (e) => {
        this.setState({
            location: e.target.value,
        });
        
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleSearch();
        }
    }

    changeSalaryMin = (e) => {
        this.setState({
            salaryMin: e.target.value,
        });
    }

    componentDidMount() {
        var params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop)
        });

        let { myTitle = '', myLocation = '' } = this.props;

        if (params.title) {
            myTitle = params.title;
        }

        if (params.location) {
            myLocation = params.location;
        }

        this.setState({
            title: myTitle || '',
            tag: params.tag || '',
            location: myLocation || '',
            salaryMin: params.salaryMin || '',
        });

        if (window.innerWidth > 768) {
            if ('requestIdleCallback' in window) {
                window.requestIdleCallback(() => this.initializeSelect2());
            } else {
                setTimeout(() => this.initializeSelect2(), 100);
            }
        } else {
            this.setupMobileNativeSelects();
        }
    }

    setupMobileNativeSelects() {
        const titleSelect = document.getElementById('title');
        const locationSelect = document.getElementById('location');

        if (titleSelect) {
            titleSelect.addEventListener('change', (e) => {
                this.handleTitleSelect(e.target.value, this.state.location);
            });
        }

        if (locationSelect) {
            locationSelect.addEventListener('change', (e) => {
                this.handleLocationSelect(e.target.value, this.state.title);
            });
        }
    }

    initializeSelect2() {
        const $ = window.$;

        $(function () {
            if (window.innerWidth > 768) {
                $("select").select2({
                    minimumResultsForSearch: 10,
                    theme: "default",
                    containerCssClass: 'custom-select2-container',
                    dropdownCssClass: 'custom-select2-dropdown',
                    closeOnSelect: true,
                    tags: true,
                    matcher: (params, data) => {
                        if (!params.term || params.term.trim() === '') {
                            return data;
                        }

                        if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
                            return data;
                        }

                        return null;
                    }
                });

                $('#title').on('select2:open', () => {
                    document.querySelector('.select2-search__field').focus();
                    document.querySelector('.select2-search__field').placeholder = "Type or select a job title";
                });

                $('#location').on('select2:open', () => {
                    document.querySelector('.select2-search__field').focus();
                    document.querySelector('.select2-search__field').placeholder = "Type or select a location";
                });
            }
        });
    }

    getMobileGroupedOptions = () => [
        {
            label: "⚙️ ENGINEERING:",
            options: [
                "Backend", "Frontend", "FullStack", "Software Engineer", "DevOps", "SRE", "QA"
            ]
        },
        {
            label: "🎨 PRODUCT & DESIGN:",
            options: [
                "Product Manager", "UI/UX", "Product Designer", "Product Owner"
            ]
        },
        {
            label: "🔍 DATA:",
            options: [
                "Data Scientist", "Data Engineer", "Data Analyst", "Data Entry", "Business Intelligence"
            ]
        },
        {
            label: "💼 BUSINESS & OPERATIONS:",
            options: [
                "Sales", "Marketing", "Business Development", "Operations Manager"
            ]
        },
        {
            label: "💬 CUSTOMER & SUPPORT:",
            options: [
                "Customer Success", "Customer Support", "Technical Support", "Help Desk"
            ]
        },
        {
            label: "👥 HR & RECRUITMENT:",
            options: [
                "HR", "Recruiter", "Talent Acquisition", "People Operations"
            ]
        },
        {
            label: "🎯 MORE ROLES:",
            options: [
                "All Roles & Categories"
            ]
        }
    ];

    getMobileGroupedLocations = () => [
        {
            label: "Global",
            options: [
                {value: "", label: "📍 Search by Location"},
                {value: "Remote.", label: "🌎 Remote - Worldwide"}
            ]
        },
        {
            label: "NORTH AMERICA:",
            options: [
                {value: "United States", label: "🇺🇸 United States"},
                {value: "Canada", label: "🇨🇦 Canada"}
            ]
        },
        {
            label: "LATIN AMERICA:",
            options: [
                {value: "LATAM", label: "🌎 LATAM"},
                {value: "Argentina", label: "🇦🇷 Argentina"},
                {value: "Brazil", label: "🇧🇷 Brazil"},
                {value: "Chile", label: "🇨🇱 Chile"},
                {value: "Colombia", label: "🇨🇴 Colombia"},
                {value: "Mexico", label: "🇲🇽 Mexico"}
            ]
        },
        {
            label: "EUROPE:",
            options: [
                {value: "Europe", label: "🇪🇺 Europe"},
                {value: "United Kingdom", label: "🇬🇧 United Kingdom"},
                {value: "Germany", label: "🇩🇪 Germany"},
                {value: "France", label: "🇫🇷 France"},
                {value: "Spain", label: "🇪🇸 Spain"}
            ]
        },
        {
            label: "ASIA PACIFIC:",
            options: [
                {value: "India", label: "🇮🇳 India"},
                {value: "Australia", label: "🇦🇺 Australia"},
                {value: "Singapore", label: "🇸🇬 Singapore"},
                {value: "Japan", label: "🇯🇵 Japan"},
                {value: "South Africa", label: "🇿🇦 South Africa"}
            ]
        },
        {
            label: "OTHERS LOCATIONS:",
            options: [
                {value: "Explore All Jobs By Locations", label: "🌎 All Jobs Locations"}
            ]
        }
    ];

    // Métodos de utilidad simplificados
    hasSpecificTitlePage = (title) => Jobfindbox2.SPECIFIC_TITLE_PAGES.includes(title);
    hasSpecificLocationPage = (location) => Jobfindbox2.SPECIFIC_LOCATION_PAGES.includes(location);
    getTitlePageUrl = (title) => `/jobs-${title.toLowerCase().replace(/\s+/g, '-')}`;
    getLocationPageUrl = (location) => `/jobs-in-${location.toLowerCase().replace(/\s+/g, '-')}`;

    // Método para construir URL con parámetros
    buildUrlWithParams = (baseUrl, params) => {
        const queryParams = Object.entries(params)
            .filter(([_, value]) => value)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join('&');
        
        return queryParams ? `${baseUrl}?${queryParams}` : baseUrl;
    }

    // Método para manejar la navegación
    navigateTo = (url) => {
        window.location.href = url;
    }

    // Método para manejar la búsqueda
    handleSearch = () => {
        const { title, location } = this.state;
        
        // Si hay tanto título como ubicación, usar comportamiento estándar con parámetros
        if (title && location) {
            this.navigateTo(this.buildUrlWithParams('/jobs', { title, location }));
            return;
        }
        
        // Si solo hay título y tiene landing page específica
        if (title && !location && this.hasSpecificTitlePage(title)) {
            this.navigateTo(this.getTitlePageUrl(title));
            return;
        }
        
        // Si solo hay ubicación y tiene landing page específica
        if (!title && location && this.hasSpecificLocationPage(location)) {
            this.navigateTo(this.getLocationPageUrl(location));
            return;
        }
        
        // Comportamiento estándar para otros casos
        this.navigateTo(this.buildUrlWithParams('/jobs', { title, location }));
    }

    // Método para manejar la selección de título
    handleTitleSelect = (selectedTitle, existingLocation = '') => {
        if (selectedTitle === "All Roles & Categories") {
            this.navigateTo('/all-jobs-by-category');
            return;
        }

        // Si ya hay una ubicación seleccionada, usar comportamiento estándar
        if (existingLocation) {
            this.navigateTo(this.buildUrlWithParams('/jobs', { 
                title: selectedTitle, 
                location: existingLocation 
            }));
            return;
        }

        // Si tiene landing page específica
        if (this.hasSpecificTitlePage(selectedTitle)) {
            this.navigateTo(this.getTitlePageUrl(selectedTitle));
            return;
        }

        // Comportamiento estándar
        this.navigateTo(this.buildUrlWithParams('/jobs', { title: selectedTitle }));
    }

    // Método para manejar la selección de ubicación
    handleLocationSelect = (selectedLocation, existingTitle = '') => {
        if (selectedLocation === "Remote." && existingTitle === "") {
            this.navigateTo('/remote-jobs-worldwide');
            return;
        }
        if (selectedLocation === "Remote, United States" && existingTitle === "") {
            this.navigateTo('/remote-jobs-usa');
            return;
        }
        if (selectedLocation === "United States" && existingTitle === "") {
            this.navigateTo('/jobs-in-usa');
            return;
        }
        if (selectedLocation === "Explore All Jobs By Locations") {
            this.navigateTo('/all-jobs-by-location');
            return;
        }

        // Si ya hay un título seleccionado, usar comportamiento estándar
        if (existingTitle) {
            this.navigateTo(this.buildUrlWithParams('/jobs', { 
                title: existingTitle, 
                location: selectedLocation 
            }));
            return;
        }

        // Si tiene landing page específica
        if (this.hasSpecificLocationPage(selectedLocation)) {
            this.navigateTo(this.getLocationPageUrl(selectedLocation));
            return;
        }

        // Comportamiento estándar
        this.navigateTo(this.buildUrlWithParams('/jobs', { location: selectedLocation }));
    }

    render() {
        const $ = window.$;

        var params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop)
        });

        if (params.title) {
            const optionExists = $('#title').find(`option[value="${params.title}"]`).length > 0;
            if (!optionExists) {
                $('#title').append(new Option(params.title, params.title));
            }
        }

        if (params.location) {
            const optionExists = $('#location').find(`option[value="${params.location}"]`).length > 0;
            if (!optionExists) {
                $('#location').append(new Option(params.location, params.location));
            }
        }

        let { myTitle = '', myLocation = '' } = this.props;

        if (params.title) {
            myTitle = params.title;
        }

        if (params.location) {
            myLocation = params.location;
        }

        $('#title').on('select2:select', (e) => {
            this.handleTitleSelect(e.target.value, myLocation);
        });

        $('#location').on('select2:select', (e) => {
            this.handleLocationSelect(e.target.value, myTitle);
        });

        const isMobile = window.innerWidth <= 768;

        return (
            <div className="section-full browse-job-find">
                <div className="container">
                    <div className="find-job-bx">
                        <form id="searcher" className="dezPlaceAni text-left">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group" style={{position: 'relative', zIndex: 1000}}>
                                        <label></label>
                                        <div className="input-group">
                                            {isMobile ? (
                                                <input 
                                                    type="search"
                                                    className="form-control"
                                                    placeholder="🔎 Search by Job Title"
                                                    value={this.state.title}
                                                    onChange={this.changeTitle}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.preventDefault();
                                                            this.handleSearch();
                                                        }
                                                    }}
                                                    enterkeyhint="search"
                                                    autoComplete="off"
                                                />
                                            ) : (
                                                <select className="form-control" 
                                                    id="title" 
                                                    name="title" 
                                                    value={this.state.title} 
                                                    onChange={this.changeTitle}
                                                    style={{width: '100%', cursor: 'pointer'}}
                                                    data-dropdown-css-class="select2-dropdown-throttled">
                                                    <option value="">🔎 Search by Job Title</option>
                                                    
                                                    {isMobile ? (
                                                        this.getMobileGroupedOptions().map(group => (
                                                            <optgroup key={group.label} label={group.label}>
                                                                {group.options.map(option => (
                                                                    <option key={option} value={option}>{option}</option>
                                                                ))}
                                                            </optgroup>
                                                        ))
                                                    ) : (
                                                        <>
                                                            <option value="Backend">Backend</option>
                                                            <option value="Frontend">Frontend</option>
                                                            <option value="FullStack">FullStack</option>
                                                            <option value="Marketing">Marketing</option>
                                                            <option value="Sales ">Sales</option>
                                                            <option value="QA">QA</option>
                                                            <option value="HR ">HR</option>
                                                            <option value="Sourcer">Sourcer</option>
                                                            <option value="Recruiter">Recruiter</option>
                                                            <option value="Talent Acquisition">Talent Acquisition</option>
                                                            <option value="Customer Success">Customer Success</option>
                                                            <option value="Customer Success Manager">Customer Success Manager</option>
                                                            <option value="Customer Support">Customer Support</option>
                                                            <option value="Customer Service">Customer Service</option>
                                                            <option value="Help Desk">Help Desk</option>
                                                            <option value="Call Center">Call Center</option>
                                                            <option value="Head of Product">Head of Product</option>
                                                            <option value="Product Manager">Product Manager</option>
                                                            <option value="Product Owner">Product Owner</option>
                                                            <option value="Product Designer">Product Designer</option>
                                                            <option value="Product Engineer">Product Engineer</option>
                                                            <option value="Product Analyst">Product Analyst</option>
                                                            <option value="Project Manager">Project Manager</option>
                                                            <option value="Program Manager">Program Manager</option>
                                                            <option value="Software Engineer">Software Engineer</option>
                                                            <option value="Senior Software Engineer">Senior Software Engineer</option>
                                                            <option value="Solutions Engineer">Solutions Engineer</option>
                                                            <option value="Technical Support">Technical Support</option>
                                                            <option value="Community Manager">Community Manager</option>
                                                            <option value="Social Media">Social Media</option>
                                                            <option value="Graphic Designer">Graphic Designer</option>
                                                            <option value="Designer">Designer</option>
                                                            <option value="Finance">Finance</option>
                                                            <option value="Data Entry">Data Entry</option>
                                                            <option value="Data Analyst">Data Analyst</option>
                                                            <option value="Data Engineer">Data Engineer</option>
                                                            <option value="Data Architect">Data Architect</option>
                                                            <option value="Data Scientist">Data Scientist</option>
                                                            <option value="Scrum Master">Scrum Master</option>
                                                            <option value="Security Engineer">Security Engineer</option>
                                                            <option value="Automation">Automation</option>
                                                            <option value="Game Tester">Game Tester</option>
                                                            <option value="SDET">SDET</option>
                                                            <option value="Part-Time">Part-Time</option>
                                                            <option value="SEO">SEO</option>
                                                            <option value="UI/UX">UI/UX</option>
                                                            <option value="DevOps">DevOps</option>
                                                            <option value="SRE">SRE</option>
                                                            <option value="Site Reliability Engineer">Site Reliability Engineer</option>
                                                            <option value="System Administrator">System Administrator</option>
                                                            <option value="Infrastructure Engineer">Infrastructure Engineer</option>
                                                            <option value="Network Engineer">Network Engineer</option>
                                                            <option value="Information Security">Information Security</option>
                                                            <option value="Cybersecurity">Cyber Security</option>
                                                            <option value="DevSecOps">DevSecOps</option>
                                                            <option value="Release Engineer">Release Engineer</option>
                                                            <option value="Release Manager">Release Manager</option>
                                                            <option value="Human Resources">Human Resources</option>
                                                            <option value="Administrator">Administrator</option>
                                                            <option value="Executive Assistant">Executive Assistant</option>
                                                            <option value="Virtual Assistant">Virtual Assistant</option>
                                                            <option value="Sales Engineer">Sales Engineer</option>
                                                            <option value="Sales Development">Sales Development</option>
                                                            <option value="Copywriter">Copywriter</option>
                                                            <option value="Content Writer">Content Writer</option>
                                                            <option value="Technical Writer">Technical Writer</option>
                                                            <option value="Underwrit">Underwriting</option>
                                                            <option value="Platform Engineer">Platform Engineer</option>
                                                            <option value="Business Development">Business Development</option>
                                                            <option value="Business Operations">Business Operations</option>
                                                            <option value="Business Analyst">Business Analyst</option>
                                                            <option value="Business Intelligence">Business Intelligence</option>
                                                            <option value="Operations Manager">Operations Manager</option>
                                                            <option value="Engineering Manager">Engineering Manager</option>
                                                            <option value="Electrical Engineer">Electrical Engineer</option>
                                                            <option value="Mechanical Engineer">Mechanical Engineer</option>
                                                            <option value="Support Engineer">Support Engineer</option>
                                                            <option value="Account Manager">Account Manager</option>
                                                            <option value="Account Executive">Account Executive</option>
                                                            <option value="Accountant">Accountant</option>
                                                            <option value="Treasury">Treasury</option>
                                                            <option value="Payroll">Payroll</option>
                                                            <option value="Paid Media">Paid Media</option>
                                                            <option value="Compensation">Compensation</option>
                                                            <option value="Consultant">Consultant</option>
                                                            <option value="Video Editor">Video Editor</option>
                                                            <option value="Animator">Animator</option>
                                                            <option value="Artist">Artist</option>
                                                            <option value="Auditor">Auditor</option>
                                                            <option value="Attorney">Attorney</option>
                                                            <option value="Lawyer">Lawyer</option>
                                                            <option value="Counsel">Counsel</option>
                                                            <option value="Legal">Legal</option>
                                                            <option value="Risk">Risk</option>
                                                            <option value="VP">VP</option>
                                                            <option value="Jr">Jr</option>
                                                            <option value="Junior">Junior</option>
                                                            <option value="Supply Chain">Supply Chain</option>
                                                            <option value="Logistics">Logistics</option>
                                                            <option value="Freelance">Freelance</option>
                                                            <option value="Developer">Developer</option>
                                                            <option value="Web Developer">Web Developer</option>
                                                            <option value="Mobile">Mobile</option>
                                                            <option value="iOS">iOS</option>
                                                            <option value="Android">Android</option>
                                                            <option value="PHP">PHP</option>
                                                            <option value="Laravel">Laravel</option>
                                                            <option value="Symfony">Symfony</option>
                                                            <option value="Wordpress">Wordpress</option>
                                                            <option value="Java ">Java</option>
                                                            <option value="JavaScript">JavaScript</option>
                                                            <option value="TypeScript">TypeScript</option>
                                                            <option value="React">React</option>
                                                            <option value="Angular">Angular</option>
                                                            <option value="Vue.js">Vue.js</option>
                                                            <option value="Python">Python</option>
                                                            <option value="Django">Django</option>
                                                            <option value="Node.js">Node.js</option>
                                                            <option value=".NET">.NET</option>
                                                            <option value="Golang">Golang</option>
                                                            <option value="C++">C++</option>
                                                            <option value="C#">C#</option>
                                                            <option value="Rust">Rust</option>
                                                            <option value="Scala">Scala</option>
                                                            <option value="Ruby">Ruby</option>
                                                            <option value="Ruby on Rails">Ruby on Rails</option>
                                                            <option value="Blockchain">Blockchain</option>
                                                            <option value="Solidity">Solidity</option>
                                                            <option value="Web3">Web3</option>
                                                            <option value=" AI ">AI</option>
                                                            <option value="AWS">AWS</option>
                                                            <option value="Tech Lead">Tech Lead</option>
                                                            <option value="Power BI">Power BI</option>
                                                            <option value="Salesforce">Salesforce</option>
                                                            <option value="Machine Learning">Machine Learning</option>
                                                        </>
                                                    )}
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label></label>
                                        <div className="input-group">
                                            {isMobile ? (
                                                <input 
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="📍 Search by Location"
                                                    value={this.state.location}
                                                    onChange={this.changeLocation}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.preventDefault();
                                                            this.handleSearch();
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                <select className="form-control" 
                                                    id="location" 
                                                    name="location" 
                                                    value={this.state.location} 
                                                    onChange={this.changeLocation}>
                                                    <option value="">📍 Search by Location</option>
                                                    <option value="Remote.">🌎 Remote - Worldwide</option>
                                                    <option value="Remote, United States">🇺🇸 Remote US Only</option>
                                                    <option value="United States">🇺🇸 United States</option>
                                                    <option value="Argentina">🇦🇷 Argentina</option>
                                                    <option value="Armenia">🇦🇲 Armenia</option>
                                                    <option value="Australia">🇦🇺 Australia</option>
                                                    <option value="Austria">🇦🇹 Austria</option>
                                                    <option value="Belgium">🇧🇪 Belgium</option>
                                                    <option value="Brazil">🇧🇷 Brazil</option>
                                                    <option value="Bulgaria">🇧🇬 Bulgaria</option>
                                                    <option value="Canada">🇨🇦 Canada</option>
                                                    <option value="Chile">🇨🇱 Chile</option>
                                                    <option value="Colombia">🇨🇴 Colombia</option>
                                                    <option value="Costa Rica">🇨🇷 Costa Rica</option>
                                                    <option value="Croatia">🇭🇷 Croatia</option>
                                                    <option value="Cyprus">🇨🇾 Cyprus</option>
                                                    <option value="Czechia">🇨🇿 Czechia</option>
                                                    <option value="Denmark">🇩🇰 Denmark</option>
                                                    <option value="Ecuador">🇪🇨 Ecuador</option>
                                                    <option value="El Salvador">🇸🇻 El Salvador</option>
                                                    <option value="Egypt">🇪🇬 Egypt</option>
                                                    <option value="Estonia">🇪🇪 Estonia</option>
                                                    <option value="Ethiopia">🇪🇹 Ethiopia</option>
                                                    <option value="Europe">🇪🇺 Europe</option>
                                                    <option value="Finland">🇫🇮 Finland</option>
                                                    <option value="France">🇫🇷 France</option>
                                                    <option value="Gabon">🇬🇦 Gabon</option>
                                                    <option value="Germany">🇩🇪 Germany</option>
                                                    <option value="Greece">🇬🇷 Greece</option>
                                                    <option value="Hungary">🇭🇺 Hungary</option>
                                                    <option value="India">🇮🇳 India</option>
                                                    <option value="Indonesia">🇮🇩 Indonesia</option>
                                                    <option value="Ireland">🇮🇪 Ireland</option>
                                                    <option value="Israel">🇮🇱 Israel</option>
                                                    <option value="Italy">🇮🇹 Italy</option>
                                                    <option value="Japan">🇯🇵 Japan</option>
                                                    <option value="LATAM">🌎 LATAM</option>
                                                    <option value="Latvia">🇱🇻 Latvia</option>
                                                    <option value="Lebanon">🇱🇧 Lebanon</option>
                                                    <option value="Liberia">🇱🇷 Liberia</option>
                                                    <option value="Lithuania">🇱🇹 Lithuania</option>
                                                    <option value="Luxembourg">🇱🇺 Luxembourg</option>
                                                    <option value="Malaysia">🇲🇾 Malaysia</option>
                                                    <option value="Mexico">🇲🇽 Mexico</option>
                                                    <option value="Morocco">🇲🇦 Morocco</option>
                                                    <option value="Namibia">🇳🇦 Namibia</option>
                                                    <option value="Netherlands">🇳🇱 Netherlands</option>
                                                    <option value="New Zealand">🇳🇿 New Zealand</option>
                                                    <option value="Nicaragua">🇳🇮 Nicaragua</option>
                                                    <option value="Nigeria">🇳🇬 Nigeria</option>
                                                    <option value="Norway">🇳🇴 Norway</option>
                                                    <option value="Pakistan">🇵🇰 Pakistan</option>
                                                    <option value="Peru">🇵🇪 Peru</option>
                                                    <option value="Philippines">🇵🇭 Philippines</option>
                                                    <option value="Poland">🇵🇱 Poland</option>
                                                    <option value="Portugal">🇵🇹 Portugal</option>
                                                    <option value="Romania">🇷🇴 Romania</option>
                                                    <option value="Serbia">🇷🇸 Serbia</option>
                                                    <option value="Singapore">🇸🇬 Singapore</option>
                                                    <option value="Slovakia">🇸🇰 Slovakia</option>
                                                    <option value="South Africa">🇿🇦 South Africa</option>
                                                    <option value="Spain">🇪🇸 Spain</option>
                                                    <option value="Sweden">🇸🇪 Sweden</option>
                                                    <option value="Switzerland">🇨🇭 Switzerland</option>
                                                    <option value="Thailand">🇹🇭 Thailand</option>
                                                    <option value="Tunisia">🇹🇳 Tunisia</option>
                                                    <option value="Turkey">🇹🇷 Turkey</option>
                                                    <option value="United Kingdom">🇬🇧 United Kingdom</option>
                                                    <option value="USA">🇺🇸 USA</option>
                                                    <option value="Ukraine">🇺🇦 Ukraine</option>
                                                    <option value="Uruguay">🇺🇾 Uruguay</option>
                                                    <option value="Ohio">🇺🇸 Ohio</option>
                                                    <option value="Florida">🇺🇸 Florida</option>
                                                    <option value="New York">🇺🇸 New York</option>
                                                    <option value="New York City">🇺🇸 New York City</option>
                                                    <option value="Boston">🇺🇸 Boston</option>
                                                    <option value="Toronto">🇨🇦 Toronto</option>
                                                    <option value="London">🇬🇧 London</option>
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-2 col-md-6" style={{display: "none"}}>
                                    <button type="submit" className="site-button btn-block">Search Jobs</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
};

export default Jobfindbox2;
