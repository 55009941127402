import React, {Component} from 'react';
import './Sponsors.css';
// import {Link} from 'react-router-dom';

class Sponsors extends Component {
    sponsors = [
        {
            id: 1,
            name: "AIApply",
            logo: "/company-logo/aiapply.jpg",
            website: "https://aiapply.com"
        },
        {
            id: 2,
            name: "SomosLingua",
            logo: "/somoslingua.png",
            website: "https://www.somoslingua.com.ar"
        }
    ];

    render() {
        return (
            <div className="sponsors-wrapper">
                <div className="sponsors-container">
                    <div className="sponsors-header">
                        <span className="sponsors-label">Trusted Partners</span>
                        <h2>Meet Our Amazing Sponsors</h2>
                        <p className="sponsors-subtitle">
                            Partnering with forward-thinking organizations in the remote work space
                        </p>
                    </div>
                    
                    <div className="sponsors-logos">
                        {this.sponsors.map(sponsor => (
                            <a 
                                key={sponsor.id} 
                                href={sponsor.website} 
                                className="sponsor-logo-link"
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <img src={sponsor.logo} alt={sponsor.name} />
                            </a>
                        ))}
                    </div>

                    <div className="sponsors-cta">
                        <p>Want to become a sponsor and reach thousands of remote workers?</p>
                        <div className="cta-buttons">
                            <a href="https://maurobonfietti.gumroad.com/l/EarlySponsorship?ref=opentoworkremote.com&utm_source=opentoworkremote.com" target="_blank" rel="noopener noreferrer" className="site-button radius-l button-lg">Become a Sponsor <span role="img" aria-label="Gold Medal">🏅</span></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Sponsors;
