import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsFrontend extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('Frontend', ''))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Find Jobs in Frontend: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in Frontend? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-frontend" />
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "What skills are essential for remote frontend development?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote frontend developers need strong proficiency in HTML, CSS, and JavaScript, plus experience with modern frameworks like React, Vue, or Angular. They should also understand responsive design, web accessibility standards, version control (Git), and have good communication skills for remote collaboration. Knowledge of UI/UX principles and testing methodologies is increasingly valuable."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How is frontend development evolving in 2025?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Frontend development is rapidly evolving with increased focus on performance optimization, accessibility, and component-based architecture. Web Assembly is expanding possibilities for browser-based applications, while frameworks continue to improve developer experience. There's growing emphasis on design systems, micro-frontends for large applications, and integration of AI-powered tools to streamline development workflows."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What's the salary range for remote frontend developers?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote frontend developer salaries vary widely based on experience, location, and specialization. Junior developers typically earn $60,000-$90,000, mid-level developers $90,000-$130,000, and senior developers $130,000-$180,000+ annually. Specialized skills in high-demand frameworks or industries can command premium rates, while freelancers often charge $50-$150+ per hour depending on expertise."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can I stand out when applying for remote frontend jobs?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "To stand out, create a polished portfolio showcasing your best work with case studies explaining your problem-solving process. Contribute to open-source projects to demonstrate collaboration skills. Master at least one modern framework deeply rather than having surface knowledge of many. Highlight remote-specific skills like self-management, communication, and async collaboration. Personalize applications to show genuine interest in each company's product and technical challenges."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in Frontend
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Find the best remote Frontend opportunities worldwide. From Junior Developers to Lead Engineers.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myTitle="Frontend" />

                    </div>

                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Frontend Jobs</h2>
                                    <p>Common questions about remote frontend development careers</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What skills are essential for remote frontend development?</h4>
                                        <p>Remote frontend developers need strong proficiency in HTML, CSS, and JavaScript, plus experience with modern frameworks like React, Vue, or Angular. They should also understand responsive design, web accessibility standards, version control (Git), and have good communication skills for remote collaboration. Knowledge of UI/UX principles and testing methodologies is increasingly valuable.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How is frontend development evolving in 2025?</h4>
                                        <p>Frontend development is rapidly evolving with increased focus on performance optimization, accessibility, and component-based architecture. Web Assembly is expanding possibilities for browser-based applications, while frameworks continue to improve developer experience. There's growing emphasis on design systems, micro-frontends for large applications, and integration of AI-powered tools to streamline development workflows.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What's the salary range for remote frontend developers?</h4>
                                        <p>Remote frontend developer salaries vary widely based on experience, location, and specialization. Junior developers typically earn $60,000-$90,000, mid-level developers $90,000-$130,000, and senior developers $130,000-$180,000+ annually. Specialized skills in high-demand frameworks or industries can command premium rates, while freelancers often charge $50-$150+ per hour depending on expertise.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How can I stand out when applying for remote frontend jobs?</h4>
                                        <p>To stand out, create a polished portfolio showcasing your best work with case studies explaining your problem-solving process. Contribute to open-source projects to demonstrate collaboration skills. Master at least one modern framework deeply rather than having surface knowledge of many. Highlight remote-specific skills like self-management, communication, and async collaboration. Personalize applications to show genuine interest in each company's product and technical challenges.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Footer />
            </div>
        );
    }
}

export default JobsFrontend;
