import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsInCostaRica extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('', 'Costa Rica'))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Jobs in Costa Rica: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in Costa Rica? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-in-costa-rica" />
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in Costa Rica
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Find the best remote opportunities in Costa Rica. From tech startups to multinational companies.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myLocation="Costa Rica" />

                    </div>
                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Remote Jobs in Costa Rica</h2>
                                    <p>Common questions about remote work opportunities for professionals in Costa Rica</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">Why is Costa Rica an ideal location for remote work?</h4>
                                        <p>Costa Rica stands out as a remote work destination due to its high-quality internet infrastructure and strategic location in the Central Time Zone, making collaboration with North American companies seamless. The country's high education standards and strong English proficiency levels make Costa Rican professionals valuable for international companies. The government's digital transformation initiatives and support for the tech sector create a robust environment for remote work. Additionally, Costa Rica's digital nomad visa and high quality of life attract both local and international remote workers, fostering a diverse professional community.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the most in-demand remote skills in Costa Rica?</h4>
                                        <p>Software development skills are highly sought after, particularly in areas like full-stack development, cloud computing, and mobile applications. Costa Rica's growing presence in the tech industry creates demand for specialists in cybersecurity, data analytics, and artificial intelligence. The country's strong service sector means customer success and technical support roles are abundant, especially for bilingual professionals. Digital marketing experts who understand both North American and Latin American markets are valuable. Project managers and business analysts with experience in agile methodologies and international teams are increasingly in demand.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How do employment and payments work for remote workers in Costa Rica?</h4>
                                        <p>Remote workers in Costa Rica typically work either as formal employees ('trabajador asalariado') or independent contractors ('trabajador independiente'). The Costa Rican Labor Code (Código de Trabajo) provides strong protections for employees, including mandatory benefits like Aguinaldo and vacation time. International payments are commonly received through platforms like Wise, PayPal, or traditional bank transfers. Independent contractors must register with the CCSS (Caja Costarricense de Seguro Social) and handle their own social security payments. The new remote work law ('Ley para Regular el Teletrabajo') establishes clear guidelines for remote work arrangements, including equipment provisions and work hours.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How can professionals thrive in Costa Rica's remote work environment?</h4>
                                        <p>Success in Costa Rica's remote work landscape requires staying current with digital skills through programs offered by institutions like the INA (Instituto Nacional de Aprendizaje) and local tech academies. Networking through Costa Rica's tech communities, such as CRTech and various meetup groups, provides valuable connections and opportunities. Understanding both local labor laws and international business practices is crucial. Many successful remote workers participate in coworking spaces and tech hubs in areas like San José and Heredia, which offer networking opportunities and professional development events. Maintaining strong English skills and cultural awareness is essential for working with international teams.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

                <Helmet>
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "Why is Costa Rica an ideal location for remote work?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Costa Rica stands out as a remote work destination due to its high-quality internet infrastructure and strategic location in the Central Time Zone, making collaboration with North American companies seamless. The country's high education standards and strong English proficiency levels make Costa Rican professionals valuable for international companies. The government's digital transformation initiatives and support for the tech sector create a robust environment for remote work. Additionally, Costa Rica's digital nomad visa and high quality of life attract both local and international remote workers, fostering a diverse professional community."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the most in-demand remote skills in Costa Rica?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Software development skills are highly sought after, particularly in areas like full-stack development, cloud computing, and mobile applications. Costa Rica's growing presence in the tech industry creates demand for specialists in cybersecurity, data analytics, and artificial intelligence. The country's strong service sector means customer success and technical support roles are abundant, especially for bilingual professionals. Digital marketing experts who understand both North American and Latin American markets are valuable. Project managers and business analysts with experience in agile methodologies and international teams are increasingly in demand."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How do employment and payments work for remote workers in Costa Rica?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote workers in Costa Rica typically work either as formal employees ('trabajador asalariado') or independent contractors ('trabajador independiente'). The Costa Rican Labor Code (Código de Trabajo) provides strong protections for employees, including mandatory benefits like Aguinaldo and vacation time. International payments are commonly received through platforms like Wise, PayPal, or traditional bank transfers. Independent contractors must register with the CCSS (Caja Costarricense de Seguro Social) and handle their own social security payments. The new remote work law ('Ley para Regular el Teletrabajo') establishes clear guidelines for remote work arrangements, including equipment provisions and work hours."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can professionals thrive in Costa Rica's remote work environment?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Success in Costa Rica's remote work landscape requires staying current with digital skills through programs offered by institutions like the INA (Instituto Nacional de Aprendizaje) and local tech academies. Networking through Costa Rica's tech communities, such as CRTech and various meetup groups, provides valuable connections and opportunities. Understanding both local labor laws and international business practices is crucial. Many successful remote workers participate in coworking spaces and tech hubs in areas like San José and Heredia, which offer networking opportunities and professional development events. Maintaining strong English skills and cultural awareness is essential for working with international teams."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>
            </div>
        );
    }
}

export default JobsInCostaRica;
