import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsInChile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('', 'Chile'))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Jobs in Chile: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in Chile? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-in-chile" />
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "What advantages does remote work offer to Chilean professionals?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote work provides Chilean professionals access to international opportunities and competitive salaries often paid in foreign currencies. Chile's position as Latin America's digital leader, with strong internet infrastructure and high tech adoption, makes it ideal for remote work. Professionals can work from anywhere in Chile, from Santiago's bustling tech scene to coastal cities like Viña del Mar or southern regions, while maintaining international-level income. The time zone alignment with North American companies (particularly EST) creates advantageous working hours for collaboration."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the most in-demand remote skills in Chile?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Software development skills are highly sought after, particularly in full-stack development, Python, and Java. Chile's growing fintech sector creates demand for professionals with experience in financial technology and blockchain. Data scientists and analysts are increasingly valuable as Chilean companies embrace data-driven decision-making. Digital marketing specialists with knowledge of Latin American markets are in high demand. There's also significant need for UX/UI designers and product managers who understand both local and international markets. Bilingual professionals (Spanish/English) are particularly valued for roles involving international collaboration."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How do contracts and payments work for remote workers in Chile?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote workers in Chile typically operate either as employees with formal contracts (Contrato de Trabajo) or as independent contractors (Boleta de Honorarios). International payments are usually received through platforms like Wise, Payoneer, or traditional bank transfers. Many professionals work as independent contractors using the simplified tax regime for 'Boletas de Honorarios'. For those employed by Chilean companies remotely, the law 'Trabajo a Distancia y Teletrabajo' establishes rights and obligations for remote work arrangements. Companies must provide necessary equipment or compensate for home office expenses. Understanding tax obligations and choosing the right contractual structure is crucial for remote workers."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What should Chilean remote workers know about legal and professional development?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote workers should understand Chile's remote work legislation, including rights to disconnect and workplace safety requirements. Professional development is crucial - many take advantage of government programs like Corfo and Sence for technical training. Networking through Chile's growing tech communities, particularly in hubs like Start-Up Chile, can lead to opportunities. Understanding international business practices while maintaining awareness of local labor laws is important. Many successful remote workers join professional associations and participate in virtual communities to stay connected with industry trends and opportunities."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in Chile
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Find the best remote opportunities in Chile. From growing startups to leading Chilean corporations.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myLocation="Chile" />

                    </div>
                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Remote Jobs in Chile</h2>
                                    <p>Common questions about remote work opportunities for professionals in Chile</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What advantages does remote work offer to Chilean professionals?</h4>
                                        <p>Remote work provides Chilean professionals access to international opportunities and competitive salaries often paid in foreign currencies. Chile's position as Latin America's digital leader, with strong internet infrastructure and high tech adoption, makes it ideal for remote work. Professionals can work from anywhere in Chile, from Santiago's bustling tech scene to coastal cities like Viña del Mar or southern regions, while maintaining international-level income. The time zone alignment with North American companies (particularly EST) creates advantageous working hours for collaboration.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the most in-demand remote skills in Chile?</h4>
                                        <p>Software development skills are highly sought after, particularly in full-stack development, Python, and Java. Chile's growing fintech sector creates demand for professionals with experience in financial technology and blockchain. Data scientists and analysts are increasingly valuable as Chilean companies embrace data-driven decision-making. Digital marketing specialists with knowledge of Latin American markets are in high demand. There's also significant need for UX/UI designers and product managers who understand both local and international markets. Bilingual professionals (Spanish/English) are particularly valued for roles involving international collaboration.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How do contracts and payments work for remote workers in Chile?</h4>
                                        <p>Remote workers in Chile typically operate either as employees with formal contracts (Contrato de Trabajo) or as independent contractors (Boleta de Honorarios). International payments are usually received through platforms like Wise, Payoneer, or traditional bank transfers. Many professionals work as independent contractors using the simplified tax regime for "Boletas de Honorarios". For those employed by Chilean companies remotely, the law "Trabajo a Distancia y Teletrabajo" establishes rights and obligations for remote work arrangements. Companies must provide necessary equipment or compensate for home office expenses. Understanding tax obligations and choosing the right contractual structure is crucial for remote workers.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What should Chilean remote workers know about legal and professional development?</h4>
                                        <p>Remote workers should understand Chile's remote work legislation, including rights to disconnect and workplace safety requirements. Professional development is crucial - many take advantage of government programs like Corfo and Sence for technical training. Networking through Chile's growing tech communities, particularly in hubs like Start-Up Chile, can lead to opportunities. Understanding international business practices while maintaining awareness of local labor laws is important. Many successful remote workers join professional associations and participate in virtual communities to stay connected with industry trends and opportunities.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

export default JobsInChile;
