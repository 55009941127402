import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsMarketing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('Marketing', ''))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Find Jobs in Marketing: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in Marketing? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-marketing" />
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "What digital marketing skills are most in-demand for remote jobs?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "The most in-demand skills for remote marketing jobs include data analytics and interpretation, content creation and strategy, SEO/SEM expertise, social media management, email marketing, and marketing automation. Proficiency with tools like Google Analytics, HubSpot, Mailchimp, and various social media platforms is essential. Additionally, skills in A/B testing, conversion rate optimization, and understanding of digital advertising platforms (Google Ads, Facebook Ads, LinkedIn Ads) are highly valued in remote marketing roles."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How is AI changing the marketing industry?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "AI is transforming marketing by enabling hyper-personalization at scale, predictive analytics for customer behavior, and automated content generation. Marketers now use AI for audience segmentation, chatbots for customer service, and optimization of ad spend across channels. While AI tools can handle routine tasks and data analysis, human marketers are focusing more on strategy, creativity, and emotional intelligence. The most successful marketers are those who can effectively collaborate with AI tools while providing the human touch that technology cannot replicate."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What metrics should remote marketing professionals track to demonstrate their value?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote marketing professionals should track both activity metrics and outcome metrics. Key performance indicators include conversion rates, customer acquisition cost (CAC), return on ad spend (ROAS), customer lifetime value (CLV), engagement rates, organic traffic growth, and lead quality scores. For content marketing, track time on page, social shares, and backlinks. When working remotely, also document productivity metrics and project completion rates to demonstrate efficiency. Always tie metrics back to business objectives and revenue impact to clearly communicate your value to the organization."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can marketers effectively collaborate with remote teams?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Effective remote marketing collaboration requires clear documentation of strategies, campaigns, and processes in shared workspaces. Use project management tools like Asana, Trello, or Monday.com to track progress and responsibilities. Schedule regular video meetings for brainstorming and feedback, while using asynchronous communication for day-to-day updates. Create standardized templates for briefs, reports, and creative assets to maintain consistency. Establish clear KPIs and reporting schedules so everyone understands goals and progress. Finally, make time for virtual team building to foster the creativity and camaraderie that marketing teams need to thrive."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in Marketing
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Find the best remote Marketing opportunities worldwide. From Marketing Specialists to CMOs.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myTitle="Marketing" />

                    </div>

                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Marketing Jobs</h2>
                                    <p>Common questions about remote marketing careers</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What digital marketing skills are most in-demand for remote jobs?</h4>
                                        <p>The most in-demand skills for remote marketing jobs include data analytics and interpretation, content creation and strategy, SEO/SEM expertise, social media management, email marketing, and marketing automation. Proficiency with tools like Google Analytics, HubSpot, Mailchimp, and various social media platforms is essential. Additionally, skills in A/B testing, conversion rate optimization, and understanding of digital advertising platforms (Google Ads, Facebook Ads, LinkedIn Ads) are highly valued in remote marketing roles.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How is AI changing the marketing industry?</h4>
                                        <p>AI is transforming marketing by enabling hyper-personalization at scale, predictive analytics for customer behavior, and automated content generation. Marketers now use AI for audience segmentation, chatbots for customer service, and optimization of ad spend across channels. While AI tools can handle routine tasks and data analysis, human marketers are focusing more on strategy, creativity, and emotional intelligence. The most successful marketers are those who can effectively collaborate with AI tools while providing the human touch that technology cannot replicate.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What metrics should remote marketing professionals track to demonstrate their value?</h4>
                                        <p>Remote marketing professionals should track both activity metrics and outcome metrics. Key performance indicators include conversion rates, customer acquisition cost (CAC), return on ad spend (ROAS), customer lifetime value (CLV), engagement rates, organic traffic growth, and lead quality scores. For content marketing, track time on page, social shares, and backlinks. When working remotely, also document productivity metrics and project completion rates to demonstrate efficiency. Always tie metrics back to business objectives and revenue impact to clearly communicate your value to the organization.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How can marketers effectively collaborate with remote teams?</h4>
                                        <p>Effective remote marketing collaboration requires clear documentation of strategies, campaigns, and processes in shared workspaces. Use project management tools like Asana, Trello, or Monday.com to track progress and responsibilities. Schedule regular video meetings for brainstorming and feedback, while using asynchronous communication for day-to-day updates. Create standardized templates for briefs, reports, and creative assets to maintain consistency. Establish clear KPIs and reporting schedules so everyone understands goals and progress. Finally, make time for virtual team building to foster the creativity and camaraderie that marketing teams need to thrive.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Footer />
            </div>
        );
    }
}

export default JobsMarketing;
