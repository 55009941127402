import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsInCanada extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('', 'Canada'))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Jobs in Canada: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in Canada? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-in-canada" />
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "What makes Canada's remote work market unique?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Canada's remote work market benefits from a strong tech ecosystem across major hubs like Toronto, Vancouver, and Montreal. The country's high internet penetration and digital infrastructure support remote work nationwide. Canadian companies often offer comprehensive benefits packages, including health coverage and retirement plans, even for remote workers. The proximity to U.S. time zones makes Canada attractive for North American companies, while maintaining distinct advantages like universal healthcare and strong worker protections. Many Canadian tech companies embrace remote-first cultures, particularly in sectors like artificial intelligence, gaming, and financial technology."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the most in-demand remote jobs in Canada?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Software development roles are highly sought after, particularly in emerging technologies like AI, machine learning, and blockchain. Canada's strong financial sector creates demand for fintech specialists and cybersecurity experts. The growing gaming industry needs remote game developers and designers. Digital health professionals are increasingly in demand, reflecting Canada's investment in healthcare technology. Project managers, business analysts, and product managers with experience in Canadian markets are valuable. There's also significant demand for bilingual professionals (English/French) in customer success and technical support roles."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the legal and tax considerations for remote work in Canada?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote workers in Canada need to understand their employment status (employee vs. independent contractor) and associated tax obligations. Employees are entitled to benefits like Employment Insurance (EI) and Canada Pension Plan (CPP) contributions. Independent contractors must manage their own tax remittances, including GST/HST if earning above the threshold. Provincial labor laws apply based on the worker's location, affecting things like overtime, vacation pay, and statutory holidays. Remote workers can often claim home office expenses on their taxes, following CRA guidelines. It's important to maintain clear documentation of work arrangements and expenses for tax purposes."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can remote workers succeed in Canada's digital economy?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Success in Canada's remote work environment requires strong digital literacy and familiarity with collaboration tools. Maintaining a professional home office setup with reliable high-speed internet is essential. Networking through Canadian tech communities, professional associations, and virtual events helps build connections. Staying current with Canadian industry certifications and continuous learning opportunities enhances career prospects. Understanding both Canadian workplace culture and cross-cultural communication is valuable when working with international teams. Many successful remote workers also participate in Canada's growing digital nomad and coworking communities."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in Canada
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Find the best remote opportunities in Canada. From innovative startups to leading Canadian corporations.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myLocation="Canada" />

                    </div>
                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Remote Jobs in Canada</h2>
                                    <p>Common questions about remote work opportunities for professionals in Canada</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What makes Canada's remote work market unique?</h4>
                                        <p>Canada's remote work market benefits from a strong tech ecosystem across major hubs like Toronto, Vancouver, and Montreal. The country's high internet penetration and digital infrastructure support remote work nationwide. Canadian companies often offer comprehensive benefits packages, including health coverage and retirement plans, even for remote workers. The proximity to U.S. time zones makes Canada attractive for North American companies, while maintaining distinct advantages like universal healthcare and strong worker protections. Many Canadian tech companies embrace remote-first cultures, particularly in sectors like artificial intelligence, gaming, and financial technology.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the most in-demand remote jobs in Canada?</h4>
                                        <p>Software development roles are highly sought after, particularly in emerging technologies like AI, machine learning, and blockchain. Canada's strong financial sector creates demand for fintech specialists and cybersecurity experts. The growing gaming industry needs remote game developers and designers. Digital health professionals are increasingly in demand, reflecting Canada's investment in healthcare technology. Project managers, business analysts, and product managers with experience in Canadian markets are valuable. There's also significant demand for bilingual professionals (English/French) in customer success and technical support roles.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the legal and tax considerations for remote work in Canada?</h4>
                                        <p>Remote workers in Canada need to understand their employment status (employee vs. independent contractor) and associated tax obligations. Employees are entitled to benefits like Employment Insurance (EI) and Canada Pension Plan (CPP) contributions. Independent contractors must manage their own tax remittances, including GST/HST if earning above the threshold. Provincial labor laws apply based on the worker's location, affecting things like overtime, vacation pay, and statutory holidays. Remote workers can often claim home office expenses on their taxes, following CRA guidelines. It's important to maintain clear documentation of work arrangements and expenses for tax purposes.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How can remote workers succeed in Canada's digital economy?</h4>
                                        <p>Success in Canada's remote work environment requires strong digital literacy and familiarity with collaboration tools. Maintaining a professional home office setup with reliable high-speed internet is essential. Networking through Canadian tech communities, professional associations, and virtual events helps build connections. Staying current with Canadian industry certifications and continuous learning opportunities enhances career prospects. Understanding both Canadian workplace culture and cross-cultural communication is valuable when working with international teams. Many successful remote workers also participate in Canada's growing digital nomad and coworking communities.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

export default JobsInCanada;
