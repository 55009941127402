import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsInColombia extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('', 'Colombia'))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Jobs in Colombia: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in Colombia? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-in-colombia" />
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in Colombia
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Find the best remote opportunities in Colombia. From innovative startups to leading Colombian companies.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myLocation="Colombia" />

                    </div>
                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Remote Jobs in Colombia</h2>
                                    <p>Common questions about remote work opportunities for professionals in Colombia</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What makes Colombia attractive for remote work opportunities?</h4>
                                        <p>Colombia has emerged as a leading tech hub in Latin America, with cities like Medellín recognized as innovative technology centers. The country's growing digital infrastructure and investment in tech education create excellent conditions for remote work. Colombian professionals benefit from time zone alignment with North American companies, making real-time collaboration efficient. The country's digital nomad visa and growing coworking ecosystem in cities like Bogotá, Medellín, and Cali make it attractive for both local and international remote workers. Additionally, Colombia's competitive cost of living allows professionals to maintain a high quality of life while working with international companies.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the most in-demand remote skills in Colombia?</h4>
                                        <p>Software development skills are highly valued, particularly in web development, mobile apps, and cloud technologies. Colombia's growing fintech sector creates demand for professionals with experience in financial technology and digital payments. Digital marketing specialists who understand both Latin American and global markets are sought after. There's significant demand for bilingual professionals in customer success and technical support roles. UI/UX designers and product managers from Colombia are valued for their creativity and understanding of international markets. Data science and artificial intelligence skills are increasingly important as Colombian companies embrace digital transformation.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How do contracts and payments work for remote workers in Colombia?</h4>
                                        <p>Remote workers in Colombia typically operate either through formal employment contracts or as independent contractors ('Prestación de Servicios'). International payments are commonly received through platforms like Wise, Payoneer, or traditional bank transfers. Many professionals work as independent contractors using the simplified tax regime for 'Personas Naturales'. The new remote work law ('Ley de Trabajo Remoto') establishes clear guidelines for remote work arrangements, including provisions for equipment, connectivity, and work hours. Companies must comply with Colombian labor laws when hiring remote employees, including social security contributions and prestaciones sociales. Understanding tax obligations and choosing the appropriate contractual structure is essential for remote workers.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How can Colombian professionals succeed in remote work?</h4>
                                        <p>Success in remote work requires strong digital skills and proficiency in collaboration tools. Many professionals benefit from programs offered by organizations like SENA and MinTIC for technical training and digital skills development. Networking through Colombia's growing tech communities, such as Colombia.dev and various tech meetups, can lead to valuable opportunities. English proficiency is crucial for international roles - many successful remote workers invest in language training. Understanding both Colombian labor laws and international business practices is important. Participating in local tech events and online communities helps stay connected with industry trends and job opportunities.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

                <Helmet>
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "What makes Colombia attractive for remote work opportunities?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Colombia has emerged as a leading tech hub in Latin America, with cities like Medellín recognized as innovative technology centers. The country's growing digital infrastructure and investment in tech education create excellent conditions for remote work. Colombian professionals benefit from time zone alignment with North American companies, making real-time collaboration efficient. The country's digital nomad visa and growing coworking ecosystem in cities like Bogotá, Medellín, and Cali make it attractive for both local and international remote workers. Additionally, Colombia's competitive cost of living allows professionals to maintain a high quality of life while working with international companies."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the most in-demand remote skills in Colombia?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Software development skills are highly valued, particularly in web development, mobile apps, and cloud technologies. Colombia's growing fintech sector creates demand for professionals with experience in financial technology and digital payments. Digital marketing specialists who understand both Latin American and global markets are sought after. There's significant demand for bilingual professionals in customer success and technical support roles. UI/UX designers and product managers from Colombia are valued for their creativity and understanding of international markets. Data science and artificial intelligence skills are increasingly important as Colombian companies embrace digital transformation."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How do contracts and payments work for remote workers in Colombia?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote workers in Colombia typically operate either through formal employment contracts or as independent contractors ('Prestación de Servicios'). International payments are commonly received through platforms like Wise, Payoneer, or traditional bank transfers. Many professionals work as independent contractors using the simplified tax regime for 'Personas Naturales'. The new remote work law ('Ley de Trabajo Remoto') establishes clear guidelines for remote work arrangements, including provisions for equipment, connectivity, and work hours. Companies must comply with Colombian labor laws when hiring remote employees, including social security contributions and prestaciones sociales. Understanding tax obligations and choosing the appropriate contractual structure is essential for remote workers."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can Colombian professionals succeed in remote work?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Success in remote work requires strong digital skills and proficiency in collaboration tools. Many professionals benefit from programs offered by organizations like SENA and MinTIC for technical training and digital skills development. Networking through Colombia's growing tech communities, such as Colombia.dev and various tech meetups, can lead to valuable opportunities. English proficiency is crucial for international roles - many successful remote workers invest in language training. Understanding both Colombian labor laws and international business practices is important. Participating in local tech events and online communities helps stay connected with industry trends and job opportunities."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>
            </div>
        );
    }
}

export default JobsInColombia;
