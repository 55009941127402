import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer2';
import { Helmet } from 'react-helmet';

const randomNumber = Math.floor(Math.random() * 5) + 10;
const bnr = require('./../../images/banner/bnr' + randomNumber + '.jpg');

class OpenToWork extends Component {
    render() {
        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>OpenToWork: Find Remote Jobs & Connect with #OpenToWork</title>
                    <meta name="description" content="Discover remote job opportunities and learn how to use #OpenToWork to connect with employers. Explore our guides, success stories, and resources to boost your job search." />
                    <link rel="canonical" href="https://www.opentoworkremote.com/opentowork" />
                </Helmet>

                <div className="page-content">
                    <div className="dez-bnr-inr jobs-category overlay-black-middle" style={{backgroundImage: "url(" + bnr + ")"}}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry">
                                <h1 className="text-white">
                                    OpenToWork: Find Remote Jobs<br /> and Connect with #OpenToWork
                                </h1>
                                <div className="breadcrumb-row">
                                    <ul className="">
                                        <li></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>	
                    <div className="content-area">
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-3 col-md-3 m-3">
                                    <div className="blog-post blog-single blog-style-1">
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 m-6">

                                    <div className="blog-post blog-single blog-style-1 p-l10 m-l10 p-r5 m-r5">
                                        <div className="dez-post-title">
                                            <br /><h2 className="post-title m-t0">Welcome to OpenToWorkRemote!</h2>
                                            Are you looking for a remote job? Learn how the #OpenToWork initiative can help you connect with employers and land your next remote position.
                                            <br /><br />
                                        </div>
                                        <div className="dez-post-text">

                                            <section id="how-to-use">
                                                <h3>How to Use #OpenToWork on LinkedIn</h3>
                                                <p><b>Maximize Your Visibility:</b> Using <b>#OpenToWork</b> on LinkedIn can boost your chances of being found by recruiters. Follow these steps to set it up on your <b>LinkedIn</b> profile:</p>
                                                <ul>
                                                    <li><b>Update Your Profile:</b> Click on your profile picture and select "View Profile."</li>
                                                    <li><b>Add Open to Work:</b> Click on the "Open to" button below your headline, then choose "Finding a new job."</li>
                                                    <li><b>Preferences:</b> Fill out the job titles, job locations, start date, and job types you're interested in.</li>
                                                    <li><b>Visibility:</b> Choose whether to share with recruiters only or with all LinkedIn members. Using #OpenToWork in your headline makes it visible to everyone.</li>
                                                    <li><b>Save Changes:</b> Click “Add to profile” to save your preferences.</li>
                                                </ul>
                                                <h5><b>Pro Tips:</b></h5>
                                                <ul>
                                                    <li>Add a <b>PROFESIONAL</b> photo.</li>
                                                    <li>Write a compelling summary highlighting your skills and experiences.</li>
                                                    <li>Engage with LinkedIn posts using <b>#OpenToWork</b> to increase your visibility.</li>
                                                </ul>
                                            </section>
                                            <section id="cta">
                                                <h2>Join Our Community</h2>
                                                <p><b>Stay Connected and Informed:</b> Join our community of remote job seekers and access exclusive content, updates, and job alerts.</p>
                                            </section>
                                            <br />
                                            <div className="text-center">
                                                <Link to = {"/"} className="site-button m-t20 outline outline-2 radius-l button-md text-center">Go to the Job Board & Find Jobs</Link>
                                            </div>

                                            <br /><br /><hr /><br />

                                            <h5>More tools and guide are coming soon...<span role="img" aria-label="Love">😃</span></h5>
                                            <p>
                                                Thank you for reading & Best wishes in your job search!
                                            </p>
                                            <p>
                                                <span role="img" aria-label="Love">👋️</span> <b> Mauro.</b> 
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};

export default OpenToWork;
