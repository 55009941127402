import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class UnlockJobs extends Component {
    render() {
        return (
            <footer className="site-footer">
                <div className="site-footer">
                    <div className="section-full job-categories content-inner-2 bg-white">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h1 className="m-b5 text-dark text-center">Get All Remote Jobs and Receive Them in Your Inbox <span role="img" aria-label="Inbox Icon">✉</span>️</h1>
                                    <h3 className="fw4 text-dark text-center">Unlock +8763 more job opportunities available. Subscribe once to access all jobs.</h3>
                                    <br />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <div className="icon-content">
                                            <div className="icon-md text-primary m-b20"><i className="ti-search"></i></div>
                                            <h5 className="dlab-tilte text-uppercase">Uncover hidden jobs</h5>
                                            <p>We reveal job opportunities that might go unnoticed in your usual searches, significantly expanding your career options.</p>
                                            <div className="rotate-icon"><i className="ti-search"></i></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <div className="icon-content">
                                            <div className="icon-md text-primary m-b20"><i className="ti-time"></i></div>
                                            <h5 className="dlab-tilte text-uppercase">Be the first to know</h5>
                                            <p>Gain exclusive access to fresh job listings, empowering you to seize the best opportunities with a competitive edge.</p>
                                            <div className="rotate-icon"><i className="ti-time"></i></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <div className="icon-content">
                                            <div className="icon-md text-primary m-b20"><i className="ti-reload"></i></div>
                                            <h5 className="dlab-tilte text-uppercase">Updated Daily</h5>
                                            <p>Our platform is constantly updated with the latest job opportunities, ensuring you have relevant information every time you check your inbox.</p>
                                            <div className="rotate-icon"><i className="ti-reload"></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <h2 className="m-b5 text-dark text-center">Access all features with just one-time payment️</h2>
                                    <h5 className="fw4 text-dark text-center">No subscriptions, no hassle. Invest once and unlock endless job opportunities.</h5>
                                    <br />
                                    <Link to={"/remote-jobs-in-your-inbox"} target="_blank" className="dez-tilte"><button className="site-button radius-l button-lg"><span role="img" aria-label="7 Days Free">⏳</span> Start FREE 7-Day Trial</button></Link>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
};

export default UnlockJobs;
