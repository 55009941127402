import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './Header.css';

class Header extends Component{
    render() {
        return (
            <div>
                <header className="site-header mo-left header fullwidth">
                    <div className="sticky-header main-bar-wraper navbar-expand-lg">
                        <div className="main-bar clearfix">
                            <div className="container clearfix">
                                <div className="logo-job">
                                    <a href="/" className="brand-container">
                                        <div className="logo-wrapper">
                                            {/* <img
                                                src={require('./../../images/logo-navidad.png')}
                                                className="logo"
                                                alt="Logo Open To Work Remote"
                                                height="60"
                                                width="60"
                                            /> */}
                                            <img
                                                src={require('./../../images/logo-otwr-scaled.png')}
                                                className="logo"
                                                alt="Logo Open To Work Remote"
                                                height="60"
                                                width="60"
                                                loading="eager"
                                                decoding="async"
                                                fetchpriority="high"
                                                style={{
                                                    contentVisibility: 'auto',
                                                    containIntrinsicSize: '60px',
                                                }}
                                            />
                                        </div>
                                        <div className="brand-text">
                                            <span className="brand-name">Open To Work Remote</span>
                                            <span className="brand-tagline">Find Your Next Remote Job Here</span>
                                        </div>
                                    </a>
                                </div>
                                <div className="post-job desktop-only">
                                    <Link 
                                        to={"/post"} 
                                        className="dez-tilte"
                                        style={{ 
                                            touchAction: 'manipulation',
                                            WebkitTapHighlightColor: 'transparent'
                                        }}
                                    >
                                        <button className="site-button radius-l">
                                            Post Job <span role="img" aria-label="Post Job">📢</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}

export default Header;
