import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsInEurope extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('', 'Europe'))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Jobs in Europe: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in Europe? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-in-europe" />
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in Europe
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Find the best remote opportunities across Europe. From innovative startups to established European corporations.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Jobfindbox myLocation="Europe" />

                    </div>
                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />

                    <div className="section-full bg-white p-t50 p-b20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-head text-center">
                                    <h2 className="m-b5">Frequently Asked Questions About Remote Jobs in Europe</h2>
                                    <p>Common questions about remote work opportunities across European countries</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What makes Europe unique for remote work opportunities?</h4>
                                        <p>Europe offers a diverse remote work landscape with strong digital infrastructure and progressive work policies. The EU's Digital Single Market initiative facilitates cross-border remote work opportunities. Major tech hubs like Berlin, Amsterdam, Dublin, and Barcelona attract global companies and remote talent. European companies often provide excellent work-life balance, comprehensive benefits, and strong worker protections. The continent's varied time zones allow for collaboration with teams worldwide, while EU regulations ensure consistent data protection (GDPR) and labor standards across member states. Many European countries now offer digital nomad visas, making it easier for remote workers to legally work across the continent.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What are the most in-demand remote skills in Europe?</h4>
                                        <p>Software development skills are highly sought after, particularly in emerging technologies like AI, blockchain, and cloud computing. Europe's strong focus on digital transformation creates demand for cybersecurity experts, data scientists, and DevOps engineers. The continent's emphasis on sustainability drives demand for professionals in green tech and renewable energy technologies. Digital marketing specialists who understand EU markets and GDPR compliance are valuable. There's significant demand for product managers and UX/UI designers who can work with multicultural teams. Multilingual professionals are particularly valued, especially those combining technical skills with language abilities. The fintech sector is booming, creating opportunities in financial technology and regulatory technology (RegTech).</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">How do employment and contracts work for remote workers in Europe?</h4>
                                        <p>Remote work in Europe can be structured through various arrangements: traditional employment contracts, independent contractor agreements, or through Employer of Record (EOR) services. EU labor laws provide strong protections including minimum paid vacation (typically 20-30 days), parental leave, and healthcare benefits. Remote workers must understand local tax implications and social security requirements in their country of residence. Many companies use European Economic Interest Groupings (EEIG) or similar structures for cross-border employment. The EU's Posted Workers Directive and remote work regulations affect how companies can hire across borders. Independent contractors should be aware of IR35-style regulations in various European countries that affect self-employment classification.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 m-b30">
                                    <div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
                                        <h4 className="dez-title m-b15">What should remote workers know about working with European companies?</h4>
                                        <p>Understanding European business culture is crucial, including respect for work-life balance and varying communication styles across regions. Remote workers should be familiar with GDPR requirements and data protection practices. European companies often emphasize collaborative decision-making and flat hierarchies. Working hours typically follow local time zones, with many companies adopting flexible schedules. Professional development is highly valued, with many companies offering training and education benefits. Networking through European tech communities, professional associations, and LinkedIn groups can lead to opportunities. Understanding different European markets and cultural nuances is valuable for career advancement. Many successful remote workers participate in European tech conferences and online communities to stay connected with industry trends.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

                <Helmet>
                    <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "What makes Europe unique for remote work opportunities?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Europe offers a diverse remote work landscape with strong digital infrastructure and progressive work policies. The EU's Digital Single Market initiative facilitates cross-border remote work opportunities. Major tech hubs like Berlin, Amsterdam, Dublin, and Barcelona attract global companies and remote talent. European companies often provide excellent work-life balance, comprehensive benefits, and strong worker protections. The continent's varied time zones allow for collaboration with teams worldwide, while EU regulations ensure consistent data protection (GDPR) and labor standards across member states. Many European countries now offer digital nomad visas, making it easier for remote workers to legally work across the continent."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are the most in-demand remote skills in Europe?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Software development skills are highly sought after, particularly in emerging technologies like AI, blockchain, and cloud computing. Europe's strong focus on digital transformation creates demand for cybersecurity experts, data scientists, and DevOps engineers. The continent's emphasis on sustainability drives demand for professionals in green tech and renewable energy technologies. Digital marketing specialists who understand EU markets and GDPR compliance are valuable. There's significant demand for product managers and UX/UI designers who can work with multicultural teams. Multilingual professionals are particularly valued, especially those combining technical skills with language abilities. The fintech sector is booming, creating opportunities in financial technology and regulatory technology (RegTech)."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How do employment and contracts work for remote workers in Europe?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Remote work in Europe can be structured through various arrangements: traditional employment contracts, independent contractor agreements, or through Employer of Record (EOR) services. EU labor laws provide strong protections including minimum paid vacation (typically 20-30 days), parental leave, and healthcare benefits. Remote workers must understand local tax implications and social security requirements in their country of residence. Many companies use European Economic Interest Groupings (EEIG) or similar structures for cross-border employment. The EU's Posted Workers Directive and remote work regulations affect how companies can hire across borders. Independent contractors should be aware of IR35-style regulations in various European countries that affect self-employment classification."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What should remote workers know about working with European companies?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Understanding European business culture is crucial, including respect for work-life balance and varying communication styles across regions. Remote workers should be familiar with GDPR requirements and data protection practices. European companies often emphasize collaborative decision-making and flat hierarchies. Working hours typically follow local time zones, with many companies adopting flexible schedules. Professional development is highly valued, with many companies offering training and education benefits. Networking through European tech communities, professional associations, and LinkedIn groups can lead to opportunities. Understanding different European markets and cultural nuances is valuable for career advancement. Many successful remote workers participate in European tech conferences and online communities to stay connected with industry trends."
                                    }
                                }
                            ]
                        }
                    `}
                    </script>
                </Helmet>
            </div>
        );
    }
}

export default JobsInEurope;
